import { SlPeople } from "react-icons/sl";
import { TbPointFilled } from "react-icons/tb";
export const hrSideBar = [
  {
    id: 1,
    name: "Başvuran Listesi",
    icon: <SlPeople size={20} />,
  },
  {
    id: 2,
    name: "Yeni Başvurular",
    icon: <TbPointFilled size={20} />,
  },
  {
    id: 3,
    name: "Değerlendirmede",
    icon: <TbPointFilled size={20} />,
  },
  {
    id: 4,
    name: "Görüşmeye çağrıldı",
    icon: <TbPointFilled size={20} />,
  },

  {
    id: 5,
    name: "Daha sonra değerlendirilecek",
    icon: <TbPointFilled size={20} />,
  },
  {
    id: 6,
    name: "İşe alındı",
    icon: <TbPointFilled size={20} />,
  },
  {
    id: 7,
    name: "Red",
    icon: <TbPointFilled size={20} />,
  },
];

export const options = [
  {
    label: "Değerlendirmede",
    value: "Değerlendirmede",
  },
  {
    label: "Görüşmeye çağrıldı",
    value: "Görüşmeye çağrıldı",
  },
  {
    label: "Daha sonra değerlendirilecek",
    value: "Daha sonra değerlendirilecek",
  },
  {
    label: "İşe alındı",
    value: "İşe alındı",
  },
  {
    label: "Red",
    value: "Red",
  },
];
