import axios from "axios";
import { config } from "src/config";
const BASE_URL = config.BASEURL + "api/";

export async function AuthLogin(username: string, password: string) {
  try {
    const data = { identifier: username, password: password };
    const response = await axios.post(
      // "https://octopus-app-ojnn7.ondigitalocean.app/api/auth/local",
      // "https://oyster-app-6dv5r.ondigitalocean.app/api/auth/local",
      // "http://localhost:1337/api/auth/local",
      `${BASE_URL}auth/local`,
      data
    );
    return response.data;
  } catch (err: any) {
    return err.message;
  }
}
