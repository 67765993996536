export const CareerInfo = [
  {
    method: "input",
    name: "workName",
    label: "Şirket Adı",
    type: "text",
    placeholder: "Şirket Adı",
  },
  {
    method: "input",
    name: "jobTitle",
    label: "Ünvan",
    placeholder: "Görev",
    type: "text",
  },
  {
    method: "date",
    name: "startDate",
    label: "Başlangıç Tarihi",
    placeholder: "01/01/1990",
    type: "date",
  },
  {
    method: "select",
    name: "isCurrentlyWorking",
    placeholder: "Seçiniz",
    label: "Hala çalışıyor musunuz?",
    type: "radio",
    options: [
      { id: "yes", value: "Evet", label: "Evet" },
      { id: "no", value: "Hayır", label: "Hayır" },
    ],
  },
];
export const careerSelection = [
  {
    id: "deneyimli",
    value: "Deneyimli",
    label: "Deneyimli",
  },
  {
    id: "deneyimsiz",
    value: "Deneyimsiz",
    label: "Deneyimsiz",
  },
];
