import CryptoJS from "crypto-js";
import { LocalConstants } from "src/constants";

export function enCrypt(data: string) {
  return CryptoJS.AES.encrypt(data, LocalConstants.StorageSecretKey).toString();
}

export function deCrypt(data: string) {
  let bytes = CryptoJS.AES.decrypt(data, LocalConstants.StorageSecretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
