import FormSection from "../../../components/SectionBox/SectionBox";
import { educationData, educationLevels } from "src/utils/data/educationInfo";
import styles from "./Education.module.scss";
interface sectionId {
  educationInfo: any;
  handleChange: (event: any) => void;
  formErrors: any;
  setFormErrors: any;
}
const Education = ({
  educationInfo,
  handleChange,
  formErrors,
  setFormErrors,
}: sectionId) => {
  return (
    <div className={styles.educationContainer}>
      <div className={styles.education}>
        <FormSection
          id={educationData[0].id}
          fields={educationData}
          formState={educationInfo}
          handleChange={handleChange}
          formContainer={styles.education}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      </div>
      {educationInfo?.educationLevel === "Lise" && (
        <>
          <FormSection
            fields={educationLevels.Lise}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      )}

      {educationInfo?.educationLevel === "Üniversite" && (
        <>
          <FormSection
            fields={educationLevels.Lise}
            formState={educationInfo}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            handleChange={handleChange}
          />
          <FormSection
            fields={educationLevels.Universite}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      )}

      {educationInfo?.educationLevel === "Yüksek Lisans" && (
        <>
          <FormSection
            fields={educationLevels.Lise}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          <FormSection
            fields={educationLevels.Universite}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          <FormSection
            fields={educationLevels.YüksekLisans}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      )}
      {educationInfo?.educationLevel === "Doktora" && (
        <>
          <FormSection
            fields={educationLevels.Lise}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          <FormSection
            fields={educationLevels.Universite}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          <FormSection
            fields={educationLevels.YüksekLisans}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          <FormSection
            fields={educationLevels.Doktora}
            formState={educationInfo}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      )}
    </div>
  );
};
export default Education;
