import styles from "./AdditionalInfo.module.scss";
import { BiCaretDown } from "react-icons/bi";
import { useState } from "react";
import FormSection from "../../../components/SectionBox/SectionBox";
import additionalInfo from "src/utils/data/additionalInfo";
interface sectionId {
  additionalInfoSection: any;
  handleChange: (event: any) => void;
  setFormErrors: any;
  formErrors: any;
}
const AdditionalInfo = ({
  additionalInfoSection,
  handleChange,
  setFormErrors,
  formErrors,
}: sectionId) => {
  const [isOpen, setIsOpen] = useState([false, false, false]);

  const handleHeaderClick = (index: any) => {
    setIsOpen((prev) => {
      const newState = prev.map((value, i) => (i === index ? !value : false));
      return newState;
    });
  };

  return (
    <section className={styles.additionalInfoSection}>
      <h5>Bu alan zorunlu değildir, ancak doldurmanız avantaj sağlayabilir.</h5>
      {additionalInfo.map((info) => (
        <div key={info.id} className={styles.additionalInfoContainer}>
          <div
            className={styles.additionalInfoHeader}
            onClick={() => handleHeaderClick(info.id)}
          >
            <h3 className={styles.header}>{info.name}</h3>
            <BiCaretDown size={30} className={styles.downArrow} />
          </div>
          {isOpen[info.id] && (
            <div className={styles.additionalInfoContent}>
              <FormSection
                fields={info.inputs}
                formState={additionalInfoSection}
                handleChange={handleChange}
                formContainer={styles.form}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            </div>
          )}
        </div>
      ))}
      <div className={styles.contactEmail}>
        <p style={{ fontSize: 10, opacity: 0.5, textAlign: "center" }}>
          Formu gönderirken herhangi bir sorunla karşılaşırsanız, lütfen İK ile
          iletişime geçin:
        </p>
        <p
          style={{
            fontSize: 10,
            color: "#000",
            opacity: 0.5,
            textAlign: "center",
          }}
        >
          E-posta:{" "}
          <a
            href="mailto:insankaynaklari@kaner.net"
            style={{ color: "#C7A671" }}
          >
            insankaynaklari@kaner.net
          </a>
        </p>
      </div>
    </section>
  );
};
export default AdditionalInfo;
