import TextArea from "antd/es/input/TextArea";
import styles from "./CoverLetter.module.scss";
interface sectionId {
  coverLetter: any;
  handleChange: any;
}

const CoverLetter = ({ coverLetter, handleChange }: sectionId) => {
  return (
    <section className={styles.coverLetterContainer}>
      <h6>
        "Ön Yazı" bölümü, başvuru formunda adayların kendilerini detaylı bir
        şekilde ifade edebilecekleri bir alandır. Bu alanda, adaylar işverene
        motivasyonlarını, ilgilerini ve yeteneklerini aktarabilirler.
      </h6>

      <TextArea
        name="coverLetter"
        className={styles.textArea}
        showCount
        maxLength={3000}
        value={coverLetter.coverLetter}
        placeholder={
          "Sayın İlgili,\n\nBaşvurumla ilgilenerek zaman ayırdığınız için teşekkür ederim.Bu başvuru ekinde, ....\n\nSaygılarımla,\n\n[Adınız]"
        }
        onChange={(e) => handleChange("coverLetter", e.target.value)}
      />
    </section>
  );
};
export default CoverLetter;
