import { ResponseResult } from "../../Models/ResponseResult";
import {
  CustomAxiosHelpersDelete,
  CustomAxiosHelpersGet,
  CustomAxiosHelpersPost,
  CustomAxiosHelpersUpdate,
} from "./CustomAxiosHelpers";

export async function GetApplicantDataById(id: string | any) {
  return (await CustomAxiosHelpersGet(
    `personal-informations/${id}?populate=imageFile`
  )) as ResponseResult;
}
export async function GetApplicantsData() {
  return (await CustomAxiosHelpersGet(
    `personal-informations?populate=imageFile`
  )) as ResponseResult;
}
export async function DeleteApplicant(id: any) {
  return (await CustomAxiosHelpersDelete(
    `personal-informations/${id}`
  )) as ResponseResult;
}
export async function GetAllReviews() {
  return (await CustomAxiosHelpersGet(`reviews`)) as ResponseResult;
}
export async function PostReview(data: any) {
  return (await CustomAxiosHelpersPost(`reviews`, data)) as ResponseResult;
}
export async function PostStatus(data: any) {
  return (await CustomAxiosHelpersPost(`statuses`, data)) as ResponseResult;
}
export async function GetStatus() {
  return (await CustomAxiosHelpersGet(`statuses`)) as ResponseResult;
}
export async function DeleteStatus(id: any) {
  return (await CustomAxiosHelpersDelete(`statuses/${id}`)) as ResponseResult;
}
export async function DeleteReview(id: any) {
  return (await CustomAxiosHelpersDelete(`reviews/${id}`)) as ResponseResult;
}
export async function UpdateStatus(data: any, id: any, updateStatus: string) {
  return (await CustomAxiosHelpersUpdate(
    `statuses/${id}`,
    data
  )) as ResponseResult;
}
export async function UpdateReview(
  data: any,
  id: any,
  updateReviewValue: string
) {
  data.applicantReview = updateReviewValue;
  return (await CustomAxiosHelpersUpdate(
    `reviews/${id}`,
    data
  )) as ResponseResult;
}
