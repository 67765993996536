export const LevelOptions = [
  {
    id: "baslangicSeviyesi",
    value: "Başlangıç Seviyesi",
    label: "Başlangıç Seviyesi",
  },
  {
    id: "ortaSeviye",
    value: "Orta Seviye",
    label: "Orta Seviye",
  },
  {
    id: "ileriSeviye",
    value: "İleri Seviye",
    label: "İleri Seviye",
  },
  {
    id: "anaDilSeviyesi",
    value: "Ana Dil Seviyesi",
    label: "Ana Dil Seviyesi",
  },
];
export const LanguageOptions = [
  { id: "ingilizce", value: "İngilizce", label: "İngilizce" },
  { id: "ispanyolca", value: "İspanyolca", label: "İspanyolca" },
  { id: "fransızca", value: "Fransızca", label: "Fransızca" },
  { id: "almanca", value: "Almanca", label: "Almanca" },
  {
    id: "çince_sadeleştirilmiş",
    value: "Çince (Sadeleştirilmiş)",
    label: "Çince (Sadeleştirilmiş)",
  },
  {
    id: "çince_geleneksel",
    value: "Çince (Geleneksel)",
    label: "Çince (Geleneksel)",
  },
  { id: "japonca", value: "Japonca", label: "Japonca" },
  { id: "korece", value: "Korece", label: "Korece" },
  { id: "italyanca", value: "İtalyanca", label: "İtalyanca" },
  { id: "portekizce", value: "Portekizce", label: "Portekizce" },
  { id: "rusça", value: "Rusça", label: "Rusça" },
  { id: "arapça", value: "Arapça", label: "Arapça" },
  { id: "hollandaca", value: "Hollandaca", label: "Hollandaca" },
  { id: "türkçe", value: "Türkçe", label: "Türkçe" },
  { id: "hintçe", value: "Hintçe", label: "Hintçe" },
  { id: "bengalce", value: "Bengalce", label: "Bengalce" },
  { id: "pencapça", value: "Pencapça", label: "Pencapça" },
  { id: "urduca", value: "Urduca", label: "Urduca" },
  { id: "tamilce", value: "Tamilce", label: "Tamilce" },
  { id: "teluguca", value: "Teluguca", label: "Teluguca" },
  { id: "marathice", value: "Marathice", label: "Marathice" },
  { id: "gujaratça", value: "Gujaratça", label: "Gujaratça" },
  { id: "kannadaca", value: "Kannadaca", label: "Kannadaca" },
  { id: "malayalamca", value: "Malayalamca", label: "Malayalamca" },
  { id: "vietnamca", value: "Vietnamca", label: "Vietnamca" },
  { id: "tayca", value: "Tayca", label: "Tayca" },
  { id: "endonezce", value: "Endonezce", label: "Endonezce" },
  {
    id: "filipince",
    value: "Filipince (Tagalog)",
    label: "Filipince (Tagalog)",
  },
  { id: "svahili", value: "Svahili", label: "Svahili" },
  { id: "hausa", value: "Hausa", label: "Hausa" },
  { id: "yorubaca", value: "Yorubaca", label: "Yorubaca" },
  { id: "igboca", value: "Igboca", label: "Igboca" },
  { id: "zuluca", value: "Zuluca", label: "Zuluca" },
  { id: "farsça", value: "Farsça", label: "Farsça" },
  { id: "peştuca", value: "Peştuca", label: "Peştuca" },
  { id: "kürtçe", value: "Kürtçe", label: "Kürtçe" },
  { id: "açe", value: "Açe dili", label: "Açe dili" },
  { id: "zazaca", value: "Zazaca", label: "Zazaca" },
  { id: "katalanca", value: "Katalanca", label: "Katalanca" },
  { id: "galce", value: "Galce", label: "Galce" },
  { id: "baskça", value: "Baskça", label: "Baskça" },
  { id: "türkmence", value: "Türkmence", label: "Türkmence" },
  { id: "kazakça", value: "Kazakça", label: "Kazakça" },
  { id: "kirgizce", value: "Kırgızca", label: "Kırgızca" },
  { id: "özbekçe", value: "Özbekçe", label: "Özbekçe" },
  { id: "tacikçe", value: "Tacikçe", label: "Tacikçe" },
  { id: "türkmenistan", value: "Türkmenistan", label: "Türkmenistan" },
  { id: "azerice", value: "Azerice", label: "Azerice" },
  { id: "gürcüce", value: "Gürcüce", label: "Gürcüce" },
  { id: "ermence", value: "Ermenice", label: "Ermenice" },
  { id: "yunanca", value: "Yunanca", label: "Yunanca" },
  { id: "bulgarca", value: "Bulgarca", label: "Bulgarca" },
  { id: "ukraynaca", value: "Ukraynaca", label: "Ukraynaca" },
  { id: "belarusça", value: "Belarusça", label: "Belarusça" },
  { id: "moldovaca", value: "Moldovaca", label: "Moldovaca" },
  { id: "lehçe", value: "Lehçe", label: "Lehçe" },
  { id: "çekçe", value: "Çekçe", label: "Çekçe" },
  { id: "slovakça", value: "Slovakça", label: "Slovakça" },
  { id: "macarca", value: "Macarca", label: "Macarca" },
  { id: "sırpça", value: "Sırpça", label: "Sırpça" },
  { id: "hırvatça", value: "Hırvatça", label: "Hırvatça" },
  { id: "slovençe", value: "Slovençe", label: "Slovençe" },
  { id: "bosnakça", value: "Bosnakça", label: "Bosnakça" },
  { id: "makedonca", value: "Makedonca", label: "Makedonca" },
  { id: "arnavutça", value: "Arnavutça", label: "Arnavutça" },
  { id: "romence", value: "Romence", label: "Romence" },
  { id: "malta_dili", value: "Malta Dili", label: "Malta Dili" },
  { id: "islandaca", value: "İzlandaca", label: "İzlandaca" },
  { id: "faroe_dili", value: "Faroe Dili", label: "Faroe Dili" },
  { id: "grönlandca", value: "Grönlandca", label: "Grönlandca" },
  { id: "İrlandaca", value: "İrlandaca", label: "İrlandaca" },
  { id: "wales_dili", value: "Galler Dili", label: "Galler Dili" },
  { id: "bretonca", value: "Bretonca", label: "Bretonca" },
  { id: "korsikaca", value: "Korsikaca", label: "Korsikaca" },
  { id: "baskça", value: "Baskça", label: "Baskça" },
];
