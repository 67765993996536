const additionalInfo = [
  {
    id: 0,
    name: "Kurs & Sertifika Bilgileri",
    inputs: [
      {
        method: "input",
        label: "Kurs Adı",
        type: "text",
        placeholder: "Kurs Adı",
        name: "courseName",
      },

      {
        method: "yearPicker",
        label: "Kurs Tarihi",
        type: "date",
        placeholder: "YYYY",
        name: "courseYear",
      },
    ],
  },
  {
    id: 1,
    name: "Teknoloji Bilgileri",
    inputs: [
      {
        method: "textarea",
        type: "text",
        label:
          "Hangi programlama dillerini biliyorsunuz? Teknoloji bilgilerinizi yazınız.",
        placeholder:
          "-Ms Office (Excel, Word, Powerpoint, Outlook)\r-C++\r-Flutter",
        name: "techKnowledge",
      },
    ],
  },
  {
    id: 2,
    name: "Referans Bilgileri",
    inputs: [
      {
        method: "input",
        type: "text",
        label: "İsim",
        placeholder: "Adı Soyadı",
        name: "referenceName",
      },
      {
        method: "input",
        type: "text",
        label: "Görevi",
        placeholder: "Görevi",
        name: "referenceJob",
      },
      {
        method: "number",
        type: "tel",
        label: "Telefon No",
        placeholder: "Telefon Numarası",
        name: "referencePhoneNumber",
      },
      {
        method: "input",
        type: "email",
        label: "E-posta",
        placeholder: "Email",
        name: "referenceEmail",
      },
    ],
  },
];
export default additionalInfo;
