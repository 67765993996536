import { Link, useParams } from "react-router-dom";
import styles from "./ReviewModal.module.scss";
import CustomisedButton from "src/components/Button/Button";
import { Button, ConfigProvider, Modal, Radio, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import logo from "../../../../images/logo.png";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  DeleteReview,
  GetAllReviews,
  GetApplicantsData,
  GetStatus,
  PostReview,
  PostStatus,
  UpdateReview,
  UpdateStatus,
} from "src/Services/API/ApplicantService";
import { ApplicantReviewDtos } from "src/Services/Dtos/ApplicantReviewDtos";
import moment from "moment";
import { getCachedUser } from "src/Services/CacheService/CacheService";
import { options } from "src/utils/data/hrSideBar";

const ReviewModal = () => {
  const [reviews, setReviews] = useState<ApplicantReviewDtos[]>([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [reviewApplicant, setReviewApplicant] = useState("");
  const [showRemoveReviewModals, setShowRemoveReviewModals] = useState<any>({});
  const [updateModalId, setUpdateModalId] = useState<number | undefined>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateReview, setUpdateReview] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [applicantStatus, setApplicantStatus] = useState("");
  const [status, setStatus] = useState<any>([]);
  const [applicantData, setApplicantData] = useState<any[]>();
  const cachedUser = getCachedUser();
  const navigate = useNavigate();
  async function fetchUsers() {
    moment.locale("tr");
    const response = await GetApplicantsData();
    if (response) {
      setApplicantData(response.result.data);
    }
  }
  useEffect(() => {
    fetchUsers().catch();
  }, []);
  const { id } = useParams();

  const handleRemoveReview = async (id: number) => {
    await DeleteReview(id);

    setReviews((prevReviews) =>
      prevReviews?.filter((review) => review.id !== id)
    );

    setShowRemoveReviewModals((prevModals: any) => ({
      ...prevModals,
      [id]: false,
    }));
  };
  const applicantReviews = async function fetchUsers() {
    const response = await GetAllReviews();

    if (response) {
      setReviews(response.result.data);
    }
  };

  useEffect(() => {
    applicantReviews().catch();
  }, []);
  if (typeof id === "undefined") {
    return (
      <div>
        <p>Applicant ID is undefined.</p>
        <Link to={"/başvuran-listesi"}>
          <p>Başvuru Listesine Dön</p>
        </Link>
      </div>
    );
  }

  const numericId = parseInt(id, 10);
  const applicantInfo = applicantData?.filter(
    (applicant: any) => applicant.id === numericId
  );
  const fullName = `${applicantInfo?.[0]?.attributes?.name} ${applicantInfo?.[0]?.attributes?.surname}`;
  const filteredReviews = reviews?.filter(
    (item) => item.attributes.applicantId === numericId
  );
  const handleReviewSubmit = async function postReview() {
    const response = await PostReview(
      JSON.stringify({
        data: {
          applicantReview: reviewApplicant,
          applicantId: id,
          reviewer: cachedUser?.email,
        },
      })
    );
    if (response) {
      const newReview = response.result.data;
      setReviews((prevReviews: any) => [...prevReviews, newReview]);
      setShowCommentModal(false);
    }
    setReviewApplicant("");
  };
  const handleReviewApplicant = (e: any) => {
    setReviewApplicant(e.target.value);
  };
  const handleUpdateReview = async (data: ApplicantReviewDtos, id: any) => {
    await UpdateReview(
      {
        data: {
          applicantReview: updateReview,
          applicantId: data?.attributes?.applicantId,
          reviewer: cachedUser?.email,
        },
      },
      id,
      updateReview
    );

    setReviews((prevReviews: any) => {
      return prevReviews.map((review: any) => {
        if (review.id === id) {
          return {
            ...review,
            attributes: {
              ...review.attributes,
              applicantReview: updateReview,
              reviewer: cachedUser?.email,
              updatedAt: review.updatedAt,
            },
          };
        }
        return review;
      });
    });
    setIsUpdate(false);
  };
  const applicationStatus = async function fetchUsers() {
    const response = await GetStatus();

    if (response) {
      setStatus(response.result.data);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    applicationStatus().catch();
  }, []);

  const statusApplicant = status?.filter(
    (item: any) => item?.attributes?.applicantId === numericId
  );
  const handleStatusSubmit = async function postStatus() {
    try {
      if (statusApplicant.length === 0) {
        const response = await PostStatus(
          JSON.stringify({
            data: {
              status: applicantStatus,
              applicantId: id,
            },
          })
        );

        if (response && response.result.data) {
          setStatus((prevStatuses: any) => [
            ...prevStatuses,
            response.result.data,
          ]);

          message.success(
            "Başvuru durumu başarılı bir şekilde değiştirilmiştir!"
          );

          setShowStatusModal(false);
        }
      } else {
        const statusItemToUpdate = status.find(
          (statusItem: any) => statusItem.attributes.applicantId === numericId
        );
        if (statusItemToUpdate) {
          const updatedStatus = { ...statusItemToUpdate };
          updatedStatus.attributes.status = applicantStatus;
          const response = await UpdateStatus(
            JSON.stringify({
              data: {
                status: applicantStatus,
                applicantId: id,
              },
            }),
            statusItemToUpdate.id,
            updatedStatus
          );
          if (response) {
            setStatus((prevStatuses: any) =>
              prevStatuses.map((statusItem: any) =>
                statusItem.attributes.applicantId === numericId
                  ? updatedStatus
                  : statusItem
              )
            );
            message.success(
              "Başvuru durumu başarılı bir şekilde değiştirilmiştir!"
            );
            setShowStatusModal(false);
          }
        }
      }
    } catch (error) {
      message.error("Başvuru durumunu belirlerken bir sorun oluştu !");
    }
  };

  return (
    <div className={styles.rootOfReview}>
      <div className={styles.logoContainer}>
        <img
          src={logo}
          width={"20%"}
          height={"40%"}
          className={styles.kanerLogo}
          alt="kaner-logo"
        />
      </div>
      <div className={styles.reviewModalSection}>
        <div className={styles.header}>
          <div className={styles.left}>
            <BsFillArrowLeftCircleFill
              onClick={() => navigate("/başvuran-listesi")}
              className={styles.icon}
              style={{ cursor: "pointer" }}
            />
            <h3 className={styles.name}>{fullName}</h3>
            {!statusApplicant?.[0]?.attributes?.status ? (
              <CustomisedButton
                labelColor="#F47C01"
                borderColor="#F47C01"
                label={"Yeni Başvuru"}
                style={styles.button}
              />
            ) : (
              <CustomisedButton
                labelColor="#F47C01"
                borderColor="#F47C01"
                style={styles.button}
                label={statusApplicant?.[0]?.attributes?.status}
              />
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.addNewCommentButton}>
              <CustomisedButton
                label="Yorum Ekle"
                labelColor="#000"
                borderColor="#000"
                style={styles.button}
                onClick={() => setShowCommentModal(true)}
              />
              <CustomisedButton
                label="Başvuru Durumunu Değiş"
                labelColor="#000"
                borderColor="#000"
                style={styles.button}
                onClick={() => setShowStatusModal(true)}
              />
            </div>
          </div>
        </div>
        {filteredReviews?.length > 0 && (
          <div className={styles.reviewsForApplicantCard}>
            {filteredReviews.map((applicant: any) => (
              <div className={styles.reviewCard} key={applicant.reviewId}>
                <p>
                  <span className={styles.span}>Değerlendirme : </span>
                  <span className={styles.span2}>
                    {applicant.attributes.applicantReview}
                  </span>
                </p>
                <p>
                  <span className={styles.span}>İK: </span>
                  <span className={styles.span2}>
                    {applicant.attributes.reviewer}
                  </span>
                </p>
                <p>
                  <span className={styles.span}>Tarih: </span>
                  <span className={styles.span2}>
                    {moment(applicant?.attributes?.updatedAt).format("lll")}
                  </span>
                </p>{" "}
                <div className={styles.updateContainer}>
                  <Button
                    className={styles.button}
                    onClick={() => {
                      setIsUpdate(true);
                      setUpdateModalId(applicant.id);
                      setUpdateReview(applicant.attributes.applicantReview);
                    }}
                  >
                    Düzelt
                  </Button>
                  <Button
                    className={styles.button}
                    onClick={() =>
                      setShowRemoveReviewModals((prevModals: any) => ({
                        ...prevModals,
                        [applicant.id]: true,
                      }))
                    }
                  >
                    Sil
                  </Button>
                  {isUpdate && (
                    <Modal
                      title="Görüş Ekle"
                      open={isUpdate}
                      width={"60%"}
                      onOk={() => handleUpdateReview(applicant, updateModalId)}
                      onCancel={() => setIsUpdate(false)}
                    >
                      <TextArea
                        name={"applicantReview"}
                        showCount
                        className={styles.textArea}
                        maxLength={1000}
                        value={updateReview}
                        placeholder={"Yorum Yap"}
                        onChange={(e: any) => setUpdateReview(e.target.value)}
                      />
                    </Modal>
                  )}
                  {showRemoveReviewModals[applicant.id] && (
                    <Modal
                      title="Görüş"
                      open={true}
                      width={"60%"}
                      onOk={() => handleRemoveReview(applicant.id)}
                      onCancel={() =>
                        setShowRemoveReviewModals((prevModals: any) => ({
                          ...prevModals,
                          [applicant.id]: false,
                        }))
                      }
                    >
                      Yorumu silmek istediğinizden emin misiniz?
                    </Modal>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {showStatusModal && (
          <Modal
            title="Görüş Ekle"
            open={showStatusModal}
            width={"60%"}
            onOk={handleStatusSubmit}
            onCancel={() => setShowStatusModal(false)}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#F47C01",
                },
              }}
            >
              <Radio.Group
                className={styles.options}
                onChange={(e: any) => setApplicantStatus(e.target.value)}
                options={options}
              />
            </ConfigProvider>
          </Modal>
        )}
        {showCommentModal && (
          <Modal
            title="Görüş Ekle"
            open={showCommentModal}
            width={"80%"}
            onOk={handleReviewSubmit}
            onCancel={() => setShowCommentModal(false)}
          >
            <TextArea
              name={"applicantReview"}
              showCount
              className={styles.textArea}
              maxLength={1000}
              value={reviewApplicant}
              placeholder={"Yorum Yap"}
              onChange={handleReviewApplicant}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ReviewModal;
