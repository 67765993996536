import { useEffect, useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { toBeFilteredData } from "src/utils/data/filter";
import { DatePicker, Input, Modal, Select } from "antd";
import CustomisedButton from "src/components/Button/Button";
import styles from "../../Pages/AdminSide/HumanResource/HumanResource.module.scss";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "moment/locale/tr";

dayjs.locale("tr");
const { RangePicker } = DatePicker;
interface Props {
  setSearhQuery: any;
  filters: any;
  setFilters: any;
  setLogoutmodal: any;
  numberOfApplicant: number;
}
export const SearchBarContainer: React.FC<Props> = ({
  setSearhQuery,
  filters,
  setFilters,
  setLogoutmodal,
  numberOfApplicant,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    const storedFilters = localStorage.getItem("filters");
    if (storedFilters) {
      setFilters(JSON.parse(storedFilters));
    }
  }, [setFilters]);
  // Update filters and save to local storage
  const updateFilters = (newFilters: { [key: string]: string | any }) => {
    setFilters(newFilters);
    localStorage.setItem("filters", JSON.stringify(newFilters));
  };
  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    updateFilters({
      ...filters,
      date: { startDate, endDate }, // Assuming date in filters is an object with start and end dates
    });
  };

  const dateFormat = "YYYY/MM/DD";
  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchBox}>
        <CustomisedButton
          label="Filtrele"
          onClick={() => setShowFilterModal(true)}
          style={styles.filteringButton}
          borderColor={""}
          labelColor={""}
        />
        <p className={styles.applicantNumber}>
          Başvuran Sayısı: {numberOfApplicant}{" "}
        </p>
        {showFilterModal && (
          <Modal
            title="Filtrele"
            open={showFilterModal}
            width={"70%"}
            onOk={() => setShowFilterModal(false)}
            closeIcon={true}
            cancelText={"Temizle"}
            onCancel={() => {
              setFilters({
                drivingLicence: "",
                location: "",
                educationInfo: "",
                departmentChoices: "",
                status: "",
                position: "",
                date: {
                  startDate: null, // Initialize with null or default values for the date filter
                  endDate: null,
                },
              });
              localStorage.removeItem("filters");
            }}
          >
            <div className={styles.filterBarContainer}>
              <div className={styles.filter}>
                <div className={styles.filterBar}>
                  <RangePicker
                    style={{ width: "100%" }}
                    onChange={handleDateChange}
                    value={
                      filters.date.startDate && filters.date.endDate // Check if both start and end dates exist
                        ? [
                            dayjs(filters.date.startDate, dateFormat),
                            dayjs(filters.date.endDate, dateFormat),
                          ]
                        : undefined
                    }
                    placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                  />
                </div>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "90%" }}
                    showSearch
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        drivingLicence: value,
                      })
                    }
                    placeholder="Ehliyet Durumu"
                    options={toBeFilteredData[0].options}
                    value={filters.drivingLicence || undefined}
                  />
                </div>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        departmentChoices: value,
                      })
                    }
                    showSearch
                    placeholder="Başvurulan Bölüm"
                    options={toBeFilteredData[3].options}
                    value={filters.departmentChoices || undefined}
                  />
                </div>
              </div>
              <div className={styles.filter}>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "100%" }}
                    mode="multiple"
                    showSearch
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        educationInfo: value,
                      })
                    }
                    placeholder="Eğitim Durumu"
                    options={toBeFilteredData[1].options}
                    value={filters.educationInfo || []}
                  />
                </div>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        location: value,
                      })
                    }
                    placeholder="İkamet İlçesi"
                    value={filters.location || undefined}
                    options={toBeFilteredData[2].options}
                  />
                </div>
              </div>
              <div className={styles.filter}>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        status: value,
                      })
                    }
                    placeholder="Başvuru Durumu"
                    options={toBeFilteredData[4].options}
                    value={filters.status || undefined}
                  />
                </div>
                <div className={styles.filterBar}>
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    onChange={(value) =>
                      updateFilters({
                        ...filters,
                        position: value,
                      })
                    }
                    placeholder="Pozisyon"
                    options={toBeFilteredData[5].options}
                    value={filters.position || undefined}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className={styles.searchBar}>
        <Input
          type="text"
          placeholder="Arama Yap.."
          name="search"
          className={styles.searchInput}
          onChange={(e: any) => setSearhQuery(e.target.value)}
        />
        <AiOutlineLogout
          color="#F47C01"
          size={30}
          onClick={() => setLogoutmodal(true)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};
