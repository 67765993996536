import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { config } from "../../config";
import { getCachedToken } from "../CacheService/CacheService";
import {
  ResponseResult,
  ResponseResultStatus,
} from "src/Models/ResponseResult";

const BASE_URL = config.BASEURL + "api/";

export async function CustomAxiosHelpersGet(
  url: string,
  cancelToken?: CancelTokenSource
) {
  try {
    const response = await axios.get(
      `${BASE_URL}${url}`,
      getHeader(cancelToken)
    );
    return handleResponce(`${BASE_URL}${url}`, "GET", response);
  } catch (error: any) {
    return handleError(`${BASE_URL}${url}`, "GET", error);
  }
}
export async function CustomAxiosHelpersPost(url: string, data: any = null) {
  try {
    const response = await axios.post(`${BASE_URL}${url}`, data, getHeader());
    return handleResponce(`${BASE_URL}${url}`, "POST", response);
  } catch (error: any) {
    return handleError(`${BASE_URL}${url}`, "POST", error);
  }
}
export async function CustomAxiosHelpersUpdate(url: string, data: any) {
  try {
    const response = await axios.put(`${BASE_URL}${url}`, data, getHeader());
    return handleResponce(`${BASE_URL}${url}`, "PUT", response);
  } catch (error: any) {
    return handleError(`${BASE_URL}${url}`, "PUT", error);
  }
}
export async function CustomAxiosHelpersDelete(url: string) {
  try {
    const response = await axios.delete(`${BASE_URL}${url}`, getHeader());
    return handleResponce(`${BASE_URL}${url}`, "DELETE", response);
  } catch (error: any) {
    return handleError(`${BASE_URL}${url}`, "DELETE", error);
  }
}

function getHeader(cancelToken?: CancelTokenSource) {
  const config: AxiosRequestConfig = {
    cancelToken: cancelToken?.token,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + getCachedToken(),
      "Content-Type": "application/json",
    },
  };
  return config;
}

function handleError(url: string, type: string, error: any) {
  if (!config.ENVIRONMENT || config.ENVIRONMENT === "development")
    return ResponceAction(error);
}
function handleResponce(url: string, type: string, response: any) {
  if (!config.ENVIRONMENT || config.ENVIRONMENT === "development")
    return ResponceAction(response);
}

function ResponceAction(result: any) {
  var { response } = result;
  if (result !== null)
    if (result.status === 200 || result.status === 201) {
      if (result.data)
        return {
          result: result.data,
          status: ResponseResultStatus.Success,
        } as ResponseResult;
      else
        return {
          result: result,
          status: ResponseResultStatus.Success,
        } as ResponseResult;
    }
  if (response && response.data !== "")
    return {
      result: response.data,
      status: ResponseResultStatus.Failed,
    } as ResponseResult;

  return {
    result: null,
    status: ResponseResultStatus.Failed,
  } as ResponseResult;
}
