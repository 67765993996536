import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getCachedToken } from "./Services/CacheService/CacheService";
import Admin from "./Pages/AdminSide/Admin";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SideBar from "./Pages/ApplicantSide/Dashboard/Dashboard";
import HumanResource from "./Pages/AdminSide/HumanResource/HumanResource";
import CurriculumVitae from "./Pages/AdminSide/HumanResource/CurriculumVitae/CurriculumVitae";
import ReviewModal from "./Pages/AdminSide/HumanResource/ReviewModal/ReviewModal";

export function Navigation() {
  function routes() {
    if (!getCachedToken()) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SideBar />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<Admin />} />
          </Routes>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/başvuran-listesi" element={<HumanResource />} />
            <Route path="/başvuran-listesi/:id" element={<CurriculumVitae />} />
            <Route
              path="/başvuran-listesi/değerlendirme/:id"
              element={<ReviewModal />}
            />
          </Routes>
        </BrowserRouter>
      );
    }
  }

  return <div>{routes()}</div>;
}
