import styles from "./CurriculumVitae.module.scss";
import moment from "moment";
const EducationSection = ({ rest }: any) => {
  const isLiseSectionVisible =
    rest.educationLevel === "Lise" ||
    rest.educationLevel === "Üniversite" ||
    rest.educationLevel === "Yüksek Lisans" ||
    rest.educationLevel === "Doktora";
  const isUniversitySectionVisible =
    rest.educationLevel === "Üniversite" ||
    rest.educationLevel === "Yüksek Lisans" ||
    rest.educationLevel === "Doktora";

  const isMasterSectionVisible =
    rest.educationLevel === "Yüksek Lisans" ||
    rest.educationLevel === "Doktora";
  const isDoctorateSectionVisible = rest.educationLevel === "Doktora";

  return (
    <>
      <div className={styles.section}>
        <h4 className={styles.sectionHeader}>Eğitim Bilgileri</h4>
        <div className={styles.applicantInfoContainer}>
          <div className={styles.labelText}>
            <h5> Eğitim Durumu:</h5>
            <span> {rest.educationLevel}</span>
          </div>
        </div>
      </div>
      {isLiseSectionVisible && <LiseSection rest={rest} />}
      {isUniversitySectionVisible && <UniverstySection rest={rest} />}
      {isMasterSectionVisible && <MasterSection rest={rest} />}
      {isDoctorateSectionVisible && <DoctorateSection rest={rest} />}
    </>
  );
};
export default EducationSection;

export const LiseSection = ({ rest }: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Lise</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5> Öğrenim Yeri:</h5>
          <span> {rest.liseName}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Yıl:</h5>
          <span> {moment(rest.liseGraduationYear).format("YYYY")}</span>
        </div>
      </div>
    </div>
  );
};

export const UniverstySection = ({ rest }: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Lisans</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5> Öğrenim Yeri:</h5>
          <span> {rest.universityName}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Yıl:</h5>
          <span> {moment(rest.universityGraduationYear).format("YYYY")}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Bölüm:</h5>
          <span> {rest.universityDepartment}</span>
        </div>
      </div>
    </div>
  );
};
export const MasterSection = ({ rest }: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Yüksek Lisans</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5> Öğrenim Yeri:</h5>
          <span> {rest.masterName}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Yıl:</h5>
          <span> {moment(rest.masterGraduationYear).format("YYYY")}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Bölüm:</h5>
          <span> {rest.masterDepartment}</span>
        </div>
      </div>
    </div>
  );
};
export const DoctorateSection = ({ rest }: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Doktora</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5> Öğrenim Yeri:</h5>
          <span> {rest.doctorateName}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Yıl:</h5>
          <span> {moment(rest.doctorateGraduationYear).format("YYYY")}</span>
        </div>
        <div className={styles.labelText}>
          <h5> Bölüm:</h5>
          <span> {rest.doctorateDepartment}</span>
        </div>
      </div>
    </div>
  );
};
