import { TfiClose } from "react-icons/tfi";
import styles from "./PhotoZoom.module.scss";
interface Prop {
  selectedImage: any;
  setSelectedImage: any;
}
const CLOUDINARYURL = process.env.REACT_APP_GET_CLOUDINARY_URL;
export const PhotoZoom = ({ selectedImage, setSelectedImage }: Prop) => {
  return (
    <section
      className={styles.photoZoomRoot}
      onClick={() => setSelectedImage("")}
    >
      <div
        onClick={() => setSelectedImage("")}
        className={styles.closeIconContainer}
      >
        <TfiClose size={20} color={"#fff"} className={styles.icon} />
      </div>
      <div className={styles.imageContainer}>
        <img
          src={`${CLOUDINARYURL}${selectedImage}`}
          alt="bigImage"
          width={"300"}
        />
      </div>
    </section>
  );
};
