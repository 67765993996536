export const data = [
  {
    id: 1,
    sectionName: "Kişiler Bilgiler",
    fields: [
      {
        method: "input",
        name: "name",
        placeholder: "Mehmet",
        label: "İsim",
        type: "text",
      },
      {
        method: "input",
        name: "surname",
        placeholder: "Karayürek",
        label: "Soyisim",
        type: "text",
      },
      {
        method: "number",
        name: "phoneNumber",
        placeholder: "541 854 92 90",
        label: "Telefon No",
        type: "tel",
      },
      {
        method: "input",
        name: "email",
        placeholder: "mehmet@gmail.com",
        label: "Email",
        type: "email",
      },
      {
        method: "date",
        name: "birthDate",
        placeholder: "dd/mm/yyyy",
        label: "Doğum Tarihi",
        type: "date",
        previousDateDisabled: true,
      },

      {
        method: "input",
        name: "birthPlace",
        placeholder: "Doğum Yeri",
        label: "Doğum Yeri",
      },
      {
        method: "select",
        name: "gender",
        placeholder: "Seçiniz",
        label: "Cinsiyet",
        options: [
          { value: "Kadın", label: "Kadın" },
          { value: "Erkek", label: "Erkek" },
        ],
      },
      {
        method: "checkbox",
        type: "checkbox",
        name: "nationality",
        placeholder: "Seçiniz",
        label: "Uyruk",
        options: [
          { id: "kktc", value: "KKTC", label: "KKTC" },
          { id: "tc", value: "TC", label: "TC" },
          { id: "ab", value: "AB", label: "AB" },
          { id: "diğer", value: "Diğer", label: "Diğer" },
        ],
      },
      {
        method: "select",
        name: "maritalStatus",
        placeholder: "Seçiniz",
        label: "Medeni Durum",
        options: [
          { value: "Evli", label: "Evli" },
          { value: "Bekar", label: "Bekar" },
        ],
      },
      {
        method: "select",
        name: "accomodationPlace",
        placeholder: "Seçiniz",
        label: "İkamet İlçesi",
        options: [
          { value: "Lefkoşa", label: "Lefkoşa" },
          { value: "Girne", label: "Girne" },
          { value: "Mağusa", label: "Mağusa" },
          { value: "Güzelyurt", label: "Güzelyurt" },
          { value: "İskele", label: "İskele" },
          { value: "Lefke", label: "Lefke" },
          { value: "Yurt Dışı", label: "Yurt Dışı" },
        ],
      },
      {
        method: "file",
        name: "imageFile",
        placeholder: "Resim Yukle",
        label: "Resim",
      },
    ],
  },
  {
    id: 3,
    sectionName: "Eğitim Bilgileri",
    fields: [
      {
        method: "select",
        name: "educationLevel",
        placeholder: "",
        label: "Eğitim Durumu",
        options: [
          { id: "1", value: "Ilk Okul", label: "Ilk Okul" },
          { id: "2", value: "Ortaokul", label: "Ortaokul" },
          { id: "3", value: "Lise", label: "Lise" },
          { id: "4", value: "Universite", label: "Universite" },
          { id: "5", value: "Yüksek Lisans", label: "Yüksek Lisans" },
          { id: "6", value: "Dokdora", label: "Dokdora" },
        ],
      },
    ],
  },
  {
    id: 5,
    sectionName: "Ön Yazı",
    fields: [
      {
        method: "richText",
        name: "coverLetter",
        placeholder:
          "Sayın İlgili,\n\nBaşvurumla ilgilenerek zaman ayırdığınız için teşekkür ederim.Bu başvuru ekinde, ....\n\nSaygılarımla,\n\n[Adınız]",
        label: "Kişisel Ön Yazı",
        type: "textarea",
      },
    ],
  },
];
