export const columnOrder = [
  "Başvuru Tarihi",
  "ADI-SOYADI",
  "Cinsiyet",
  "Doğum Tarihi",
  "Uyruk",
  "Tel.",
  "Ehliyet",
  "E-mail",
  "Adres Bölge",
  "Adres Detay",
  "En Son Bitirdiği Okul",
  "Okul Adı",
  "Bölüm",
  "Lisan",
  "Konuşma",
  "Yazma",
  "Anlama",
  "2. Lisan",
  "2. Lisan Konuşma",
  "2. Lisan Yazma",
  "2. Lisan Anlama",
  "3. Lisan",
  "3. Lisan Konuşma",
  "3. Lisan Yazma",
  "3. Lisan Anlama",
  "Firma",
  "Görev",
  "Firma2",
  "Görev2",
  "Firma3",
  "Görev3",
  "Pozisyon1",
  "Pozisyon2",
  "Pozisyon3",
  "Pozisyon4",
  "Referans1",
  "Referans2",
  "Referans3",
];
