import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { notification } from "antd";
import styles from "./Admin.module.scss";
import { AuthLogin } from "src/Services/API/AuthService";
import {
  setCachedToken,
  setCachedUser,
} from "src/Services/CacheService/CacheService";
import { UsersDto } from "src/Services/Dtos/Users";
const Admin = () => {
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const value = {
      identifier: userDetails.email,
      password: userDetails.password,
    };
    const response = await AuthLogin(value.identifier, value.password);
    const user = response.user as UsersDto;

    if (user) {
      setCachedUser(user);
      setCachedToken(response.jwt);
      navigate("/başvuran-listesi");
      window.location.reload();
      notification["success"]({
        message: "Login Successful",
        description: ` Hoşgeldin ${user.username} !`,
        duration: 5,
      });
    } else {
      notification["error"]({
        message: "Hata",
        description: "Geçerli bilgiler girdiğinizden emin olun! ",
        duration: 5,
      });
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };
  return (
    <section className={styles.adminPage}>
      <div className={styles.pageContainer}>
        <div className={styles.logo}>
          <img
            src={logo}
            width={"40%"}
            height={"60%"}
            className={styles.kanerLogo}
            alt="kaner-logo"
          />
        </div>

        <div className={styles.loginInput}>
          <label className={styles.inputLabel}>
            E-mail
            <input
              className={styles.input}
              type="email"
              placeholder="adminIK@gmail.com"
              name="email"
              onChange={handleChange}
            />
          </label>
          <label className={styles.inputLabel}>
            Şifre
            <input
              className={styles.input}
              type={showPassword ? "text" : "password"}
              placeholder="*********"
              name="password"
              onChange={handleChange}
            />
            <button
              className={styles.showPasswordButton}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Şifreyi Gizle" : "Şifreyi Göster"}
            </button>
          </label>
          <button className={styles.button} onClick={handleSubmit}>
            Giriş Yap
          </button>
        </div>
      </div>
    </section>
  );
};
export default Admin;
