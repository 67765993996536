import { Dispatch, SetStateAction } from "react";
import styles from "./ApplicantTable.module.scss";
import "moment/locale/tr";
import { ApplicantReviewDtos } from "src/Services/Dtos/ApplicantReviewDtos";
import { ApplicantCard } from "./ApplicantCard/ApplicantCard";

interface ApplicantDataProps {
  applicant: any;
  handleApplicantId: any;
  reviews: any;
  status: any;
  handleReviewModalPage: any;
  setStatus: Dispatch<SetStateAction<any>>;
  setReviews: Dispatch<SetStateAction<ApplicantReviewDtos[]>>;
}

const ApplicantTable: React.FC<ApplicantDataProps> = ({
  applicant,
  handleApplicantId,
  handleReviewModalPage,
  reviews,
  status,
}) => {
  return (
    <div className={styles.applicantTableRoot}>
      <div className={styles.applicantCard}>
        {applicant?.map((applicant: any) => (
          <ApplicantCard
            key={applicant.key}
            status={status}
            applicant={applicant}
            reviews={reviews}
            handleApplicantId={handleApplicantId}
            handleReviewModal={handleReviewModalPage}
          />
        ))}
      </div>
    </div>
  );
};
export default ApplicantTable;
