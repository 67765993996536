export const educationData = [
  {
    id: 1,
    sectionName: "Eğitim Bilgileri",
    method: "select",
    name: "educationLevel",
    placeholder: "Seçiniz",
    label: "Eğitim Durumu ",
    options: [
      { id: "Ilkokul", label: "İlkokul" },
      { id: "Ortaokul", label: "Ortaokul" },
      { id: "Lise", label: "Lise" },
      { id: "Üniversite", label: "Üniversite" },
      { id: "Yüksek Lisans", label: "Yüksek Lisans" },
      { id: "Doktora", label: "Doktora" },
    ],
  },
];
export const educationLevels = {
  Lise: [
    {
      id: 1,
      name: "liseName",
      method: "input",
      label: "Lise",
      placeholder: "Lise Adı",
    },
    {
      id: 2,
      method: "yearPicker",
      type: "date",
      name: "liseGraduationYear",
      label: "Mezuniyet Yılı",
      placeholder: "1990",
    },
  ],
  Universite: [
    {
      method: "input",
      name: "universityName",
      label: "Üniversite",
      placeholder: "Üniversite Adı",
    },

    {
      method: "input",
      name: "universityDepartment",
      label: "Bölüm",
      placeholder: "İşletme",
      type: "text",
    },
    {
      method: "yearPicker",
      name: "universityGraduationYear",
      label: "Mezuniyet Yılı",
      placeholder: "01/01/1990",
      type: "date",
    },
  ],
  YüksekLisans: [
    {
      method: "input",
      name: "masterName",
      label: "Yüksek Lisans",
      placeholder: "Yüksek Lisans Adı",
    },

    {
      method: "input",
      name: "masterDepartment",
      label: "Bölüm",
      placeholder: "Sayısal Yöntemler ",
      type: "text",
    },
    {
      method: "yearPicker",
      name: "masterGraduationYear",
      label: "Mezuniyet Yılı",
      placeholder: "01/01/1990",
      type: "date",
    },
  ],
  Doktora: [
    {
      method: "input",
      name: "doctorateName",
      label: "Doktora",
      placeholder: "Doktora Adı",
    },

    {
      method: "input",
      name: "doctorateDepartment",
      label: "Bölüm",
      placeholder: "Finans",
      type: "text",
    },
    {
      method: "yearPicker",
      name: "doctorateGraduationYear",
      label: "Mezuniyet Yılı",
      placeholder: "01/01/1990",
      type: "date",
    },
  ],
};
