import { useState } from "react";
import { Select } from "antd";
import { applicationInfo } from "src/utils/data/applicationInfo";
import styles from "./ApplicationInfo.module.scss";

const { Option } = Select;

interface SectionId {
  handleChange: (fieldName: string, values: string[]) => void;
  selectedValues: { [key: string]: string[] };
  formErrors: any;
  setFormErrors: any;
}

const ApplicationInfo = ({
  handleChange,
  selectedValues,
  formErrors,
  setFormErrors,
}: SectionId) => {
  const [internalSelectedValues, setInternalSelectedValues] = useState<{
    [key: string]: string[];
  }>(selectedValues);
  const getErrorMessage = (fieldName: string) => {
    if (formErrors && formErrors[fieldName]) {
      return formErrors[fieldName];
    }
    return "";
  };
  const clearError = (fieldName: string) => {
    if (formErrors[fieldName]) {
      setFormErrors((prevFormErrors: any) => ({
        ...prevFormErrors,
        [fieldName]: "",
      }));
    }
  };

  const handleSelectionChange = (fieldName: string, values: string[]) => {
    clearError(fieldName);
    setInternalSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [fieldName]: values,
    }));

    handleChange(fieldName, values);
  };
  return (
    <div className={styles.applicationInfoContainer}>
      {applicationInfo.map((item) => (
        <div key={item.id}>
          <p className={styles.label}> {item.label}</p>

          <Select
            className={styles.selectionInput}
            mode="multiple"
            placeholder={item.placeholder}
            value={internalSelectedValues[item.name] || []}
            onChange={(values) => handleSelectionChange(item.name, values)}
          >
            {item.options
              .slice()
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option) => (
                <Option key={option.id} value={option.label}>
                  {option.label}
                </Option>
              ))}
          </Select>
          {formErrors && (
            <p className={styles.errorText}>{getErrorMessage(item.name)}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplicationInfo;
