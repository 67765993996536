import { InputInfo } from "./sidebar";

export const formValidation = (step: number, form: InputInfo) => {
  const errors: Partial<InputInfo> = {};
  if (step === 0) {
    if (!form.name) {
      errors.name = "İsminizi girmelisiniz.";
    } else if (!form.surname) {
      errors.surname = "Soyisminizi girmelisiniz.";
    } else if (!form.phoneNumber) {
      errors.phoneNumber = "Geçerli bir telefon numarası girmelisiniz.";
    } else if (!form.email) {
      errors.email = "Geçerli bir e-posta adresi girmelisiniz.";
    } else if (!form.birthDate) {
      errors.birthDate = "Doğum tarihinizi girmelisiniz.";
    } else if (!form.birthPlace) {
      errors.birthPlace = "Doğum yerinizi girmelisiniz.";
    } else if (!form.gender) {
      errors.gender = "Cinsiyetinizi seçmelisiniz.";
    } else if (!form.nationality) {
      errors.nationality = "Uyruk seçmelisiniz.";
    } else if (!form.maritalStatus) {
      errors.maritalStatus = "Medeni durum belirtmelisiniz.";
    } else if (!form.accomodationPlace) {
      errors.accomodationPlace = "İkamet İlçesi belirtmelisiniz.";
    }
  } else if (step === 1) {
    if (form.departmentChoices.length === 0) {
      errors.departmentChoices = "Bölüm belirtmelisiniz.";
    } else if (form.position.length === 0) {
      errors.position = "Pozisyon belirtiniz.";
    } else if (form.drivingLicence.length === 0) {
      errors.drivingLicence = "Ehliyet durumunuzu belirtmelisiniz.";
    }
  } else if (step === 2) {
    if (!form.educationLevel) {
      errors.educationLevel = "Eğitim durumunuzu belirtmelisiniz.";
    } else if (form.educationLevel === "Lise") {
      if (!form.liseName) {
        errors.liseName = "Okuduğunuz lisenin ismini belirtmelisiniz.";
      } else if (!form.liseGraduationYear) {
        errors.liseGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      }
    } else if (form.educationLevel === "Üniversite") {
      if (!form.liseName) {
        errors.liseName = "Okuduğunuz lisenin ismini belirtmelisiniz.";
      } else if (!form.liseGraduationYear) {
        errors.liseGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityName) {
        errors.universityName =
          "Okuduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.universityGraduationYear) {
        errors.universityGraduationYear =
          "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityDepartment) {
        errors.universityDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      }
    } else if (form.educationLevel === "Yüksek Lisans") {
      if (!form.liseName) {
        errors.liseName = "Mezun olduğunuz lisenin ismini belirtmelisiniz.";
      } else if (!form.liseGraduationYear) {
        errors.liseGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityName) {
        errors.universityName =
          "Mezun olduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.universityGraduationYear) {
        errors.universityGraduationYear =
          "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityDepartment) {
        errors.universityDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      } else if (!form.masterName) {
        errors.masterName =
          "Mezun olduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.masterGraduationYear) {
        errors.masterGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.masterDepartment) {
        errors.masterDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      }
    } else if (form.educationLevel === "Doktora") {
      if (!form.liseName) {
        errors.liseName = "Mezun olduğunuz lisenin ismini belirtmelisiniz.";
      } else if (!form.liseGraduationYear) {
        errors.liseGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityName) {
        errors.universityName =
          "Mezun olduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.universityGraduationYear) {
        errors.universityGraduationYear =
          "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.universityDepartment) {
        errors.universityDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      } else if (!form.masterName) {
        errors.masterName =
          "Mezun olduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.masterGraduationYear) {
        errors.masterGraduationYear = "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.masterDepartment) {
        errors.masterDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      } else if (!form.doctorateName) {
        errors.doctorateName =
          "Mezun olduğunuz üniversitenin ismini belirtmelisiniz.";
      } else if (!form.doctorateGraduationYear) {
        errors.doctorateGraduationYear =
          "Mezun olduğunuz yılı belirtmelisiniz.";
      } else if (!form.doctorateDepartment) {
        errors.doctorateDepartment = "Mezun olduğunuz bölümü belirtmelisiniz.";
      }
    }
  } else if (step === 3) {
    if (!form.careerInfo) {
      errors.careerInfo = "İş deneyiminizi belirtmelisiniz.";
    } else if (form.careerInfo === "Deneyimli") {
      if (form.experience.length >= 0) {
        if (!form?.experience?.[0]?.workName) {
          errors.workName = "Şirket ismini belirtmelisiniz.";
        }
        if (!form?.experience?.[0]?.jobTitle) {
          errors.jobTitle = "Ünvanınızı belirtmelisiniz.";
        }
        if (!form?.experience?.[0]?.startDate) {
          errors.startDate = "Başlangıç tarihini belirtmelisiniz.";
        }
        if (!form?.experience?.[0]?.isCurrentlyWorking) {
          errors.isCurrentlyWorking = "İşaretlemeniz gerekmektedir.";
        }
      }
    }
  } else if (step === 4) {
    if (form.languageName) {
      if (!form.languageLevel) {
        errors.languageLevel =
          "Dili hangi seviyede bildiğinizi belirtmelisiniz.";
      }
    }
  } else if (step === 6) {
    if (form.referenceName) {
      if (!form.referenceJob) {
        errors.referenceJob =
          "Referans ettiğiniz kişinin görevini belirlemelisiniz.";
      } else if (!form.referencePhoneNumber) {
        errors.referencePhoneNumber =
          "Referans ettiğiniz kişinin telefon numarasını belirtmelisiniz.";
      } else if (!form.referenceEmail) {
        errors.referenceEmail =
          "Referans ettiğiniz kişinin e-posta adresini belirtmelisiniz.";
      }
    }
  }

  return errors;
};
