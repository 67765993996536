import { data } from "src/utils/data/personalInfo";
import FormSection from "../../../components/SectionBox/SectionBox";
import styles from "./PersonalInfo.module.scss";

interface sectionId {
  id: number;
  personalInfo: any;
  handleChange: (event: any) => void;
  selectedValues: any;
  formErrors: any;
  setFormErrors: any;
}
const PersonalInfo = ({
  id,
  personalInfo,
  handleChange,
  selectedValues,
  formErrors,
  setFormErrors,
}: sectionId) => {
  const personalInfoSection = data.find((section) => section.id === id);

  return (
    <div>
      <FormSection
        id={personalInfoSection?.id}
        fields={personalInfoSection?.fields}
        formState={personalInfo}
        formContainer={styles.personalInfoContainer}
        selectedValues={selectedValues}
        handleChange={handleChange}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </div>
  );
};

export default PersonalInfo;
