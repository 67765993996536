import {
  BsPersonCheckFill,
  BsInfoCircleFill,
  BsFillBagFill,
  BsPlusCircleFill,
} from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import Career from "src/Pages/ApplicantSide/Career/Career";
import LanguageInfo from "src/Pages/ApplicantSide/LanguageInfo/LanguageInfo";
import AdditionalInfo from "src/Pages/ApplicantSide/AdditionalInfo/AdditionalInfo";
import PersonalInfo from "src/Pages/ApplicantSide/PersonalInfo/PersonalInfo";
import { MdOutlineComputer } from "react-icons/md";
import { IoEarthOutline } from "react-icons/io5";
import ApplicationInfo from "src/Pages/ApplicantSide/ApplicationInfo/ApplicationInfo";
import CoverLetter from "src/Pages/ApplicantSide/CoverLetter/CoverLetter";
import Education from "src/Pages/ApplicantSide/Education/Education";
export const Sidebar = [
  {
    id: 1,
    name: "Kişisel Bilgiler",
    icon: <BsPersonCheckFill size={20} />,
  },
  {
    id: 2,
    name: "Başvuru Bilgileri",
    icon: <BsInfoCircleFill size={20} />,
  },
  {
    id: 3,
    name: "Eğitim Bilgileri",
    icon: <FaUserGraduate size={20} />,
  },
  {
    id: 4,
    name: "Kariyer Bilgileri",
    icon: <BsFillBagFill size={20} />,
  },

  {
    id: 5,
    name: "Yabancı Dil Bilgileri",
    icon: <IoEarthOutline size={20} />,
  },
  {
    id: 6,
    name: "Ön Yazı ",
    icon: <MdOutlineComputer size={20} />,
  },
  {
    id: 7,
    name: "Ek Bilgiler",
    icon: <BsPlusCircleFill size={20} />,
  },
];

export const renderSection = (
  currentStep: number,
  handleChange: any,
  form: any,
  selectedValues: any,
  styles: any,
  formErrors: any,
  setFormErrors: any
) => {
  switch (currentStep) {
    case 0:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Kişisel Bilgiler </h3>
          </div>
          <PersonalInfo
            handleChange={handleChange}
            selectedValues={selectedValues}
            id={1}
            personalInfo={form}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      );

    case 1:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Başvuru Bilgileri</h3>
          </div>
          <ApplicationInfo
            selectedValues={selectedValues}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      );
    case 2:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Eğitim Bilgileri</h3>
          </div>
          <Education
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            educationInfo={form}
          />
        </>
      );
    case 3:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Kariyer Bilgileri</h3>
          </div>
          <Career
            handleChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            careerInfo={form}
          />
        </>
      );

    case 4:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Yabancı Dil Bilgileri</h3>
          </div>

          <LanguageInfo handleChange={handleChange} languageInfo={form} />
        </>
      );

    case 5:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Ön Yazı</h3>
          </div>
          <CoverLetter coverLetter={form} handleChange={handleChange} />
        </>
      );
    case 6:
      return (
        <>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Ek Bilgiler</h3>
          </div>
          <AdditionalInfo
            handleChange={handleChange}
            additionalInfoSection={form}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </>
      );
    default:
      return (
        <PersonalInfo
          selectedValues={selectedValues}
          id={1}
          handleChange={handleChange}
          personalInfo={form}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      );
  }
};
export type InputInfo = {
  imageFile: File | any;
  // nationality: string[];
  drivingLicence: string[] | string;
  departmentChoices: [] | string;
  position: [] | string;
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
  birthDate: string;
  gender: string;
  birthPlace: string;
  accomodationPlace: string;
  maritalStatus: string;
  educationLevel: string;
  liseName: string;
  uniName: string;
  masterName: string;
  doctorateName: string;
  careerInfo: string;
  workName: string;
  previousPosition: string;
  isCurrentlyWorking: string;
  otherLanguage: string;
  courseName: string;
  institutionName: string;
  experience: {
    workName: string;
    jobTitle: string;
    startDate: any;
    isCurrentlyWorking: boolean;
  }[];
  language: { languageName: string; languageLevel: string }[];
  [key: string]:
    | string[]
    | string
    | File
    | { languageName: string; languageLevel: string }[]
    | {
        workName: string;
        jobTitle: string;
        startDate: any;
        isCurrentlyWorking: boolean;
      }[]
    | undefined;
};
export const initialFormState: InputInfo = {
  name: "",
  imageFile: [],
  surname: "",
  phoneNumber: "",
  email: "",
  birthDate: "",
  birthPlace: "",
  accomodationPlace: "",
  maritalStatus: "",
  // nationality: [],
  gender: "",
  drivingLicence: [],
  departmentChoices: [],
  position: [],
  educationLevel: "",
  liseName: "",
  uniName: "",
  masterName: "",
  doctorateName: "",
  careerInfo: "",
  workName: "",
  previousPosition: "",
  isCurrentlyWorking: "",
  otherLanguage: "",
  courseName: "",
  language: [],
  experience: [],
  institutionName: "",
};
