export const toBeFilteredData = [
  {
    options: [
      {
        value: "Ehliyetim Yok",
        label: "Ehliyetim Yok",
      },
      {
        value: "A Sınıfı - Otobüs",
        label: "A Sınıfı - Otobüs",
      },
      {
        value: "B Sınıfı - Kamyon, Tır",
        label: "B Sınıfı - Kamyon, Tır",
      },
      {
        value: "C Sınıfı - Minibüs",
        label: "C Sınıfı - Minibüs",
      },
      {
        value: "D Sınıfı - Manuel",
        label: "D Sınıfı - Manuel",
      },
      {
        value: "D Sınıfı - Otomatik vites",
        label: "D Sınıfı - Otomatik vites",
      },
      {
        value: "E Sınıfı - Traktör, ATV",
        label: "E Sınıfı - Traktör, ATV",
      },
      {
        value: "F Sınıfı - Palet üzeri araçlar",
        label: "F Sınıfı - Palet üzeri araçlar",
      },
      {
        value: "G Sınıfı - Yol Silindiri",
        label: "G Sınıfı - Yol Silindiri",
      },
      {
        value: "H Sınıfı - Motorsiklet",
        label: "H Sınıfı - Motorsiklet",
      },
      {
        value: "K Sınıfı - Greyder ve Jiletli araçlar",
        label: "K Sınıfı - Greyder ve Jiletli araçlar",
      },
    ],
  },
  {
    options: [
      {
        value: "İlkokul",
        label: "İlkokul",
      },
      {
        value: "Ortaokul",
        label: "Ortaokul",
      },
      {
        value: "Lise",
        label: "Lise",
      },
      {
        value: "Üniversite",
        label: "Üniversite",
      },
      {
        value: "Yüksek Lisans",
        label: "Yüksek Lisans",
      },
      {
        value: "Doktora",
        label: "Doktora",
      },
    ],
  },
  {
    options: [
      { value: "", label: "Tümü" },
      {
        value: "Lefkoşa",
        label: "Lefkoşa",
      },
      {
        value: "Girne",
        label: "Girne",
      },
      {
        value: "Mağusa",
        label: "Mağusa",
      },
      {
        value: "Güzelyurt",
        label: "Güzelyurt",
      },
      { value: "İskele", label: "İskele" },
      { value: "Lefke", label: "Lefke" },
      { value: "Yurt Dışı", label: "Yurt Dışı" },
    ],
  },
  {
    options: [
      { value: "", label: "Tümü" },
      { value: "IT-Bilgi Teknolojileri", label: "IT-Bilgi Teknolojileri" },
      { value: "İK-İnsan Kaynakları", label: "İK-İnsan Kaynakları" },
      { value: "İdari İşler", label: "İdari İşler" },
      { value: "Muhasebe", label: "Muhasebe" },
      { value: "Satın Alma", label: "Satın Alma" },
      { value: "Filo", label: "Filo" },
      { value: "Gümrükleme & Lojistik", label: "Gümrükleme & Lojistik" },
      { value: "Diageo", label: "Diageo" },
      { value: "Merkez Depo", label: "Merkez Depo" },
      { value: "IMP - Imperial", label: "IMP - Imperial" },
      { value: "PMI - Philip Morris", label: "PMI - Philip Morris" },
      { value: "Coca-Cola", label: "Coca-Cola" },
      { value: "icPazar", label: "İç Pazar" },
      { value: "Gloria Jeans Coffees", label: "Gloria Jeans Coffees" },
      { value: "Dominos", label: "Dominos" },
      { value: "Gıda Satış", label: "Gıda Satış" },
      { value: "Ercan Duty Free", label: "Ercan Duty Free" },
      { value: "Kaner Brand House", label: "Kaner Brand House" },
    ],
  },
  {
    options: [
      { value: "", label: "Tümü" },
      { value: "Değerlendirmede", label: "Değerlendirmede" },
      { value: "Görüşmeye çağrıldı", label: "Görüşmeye çağrıldı" },
      {
        value: "Daha sonra değerlendirilecek",
        label: "Daha sonra değerlendirilecek",
      },
      { value: "İşe alındı", label: "İşe alındı" },
      { value: "Red", label: "Red" },
    ],
  },
  {
    options: [
      {
        value: "",
        label: "Tümü",
      },
      {
        value: "Operasyon Destek Personeli",
        label: "Operasyon Destek Personeli",
      },
      { value: "Şoför", label: "Şoför" },
      {
        value: "Stratejik Planlama ve İş Geliştirme Müdürü",
        label: "Stratejik Planlama ve İş Geliştirme Müdürü",
      },
      { value: "Raporlama Uzmanı", label: "Raporlama Uzmanı" },
      { value: "Yönetici Asistan", label: "Yönetici Asistan" },
      { value: "Tedarik Zinciri Müdürü", label: "Tedarik Zinciri Müdürü" },
      { value: "Stok Sayım Sorumlusu", label: "Stok Sayım Sorumlusu" },
      { value: "Stok Sayım Personeli", label: "Stok Sayım Personeli" },
      {
        value: "Ticari Satın Alma ve Tedarik Uzmanı",
        label: "Ticari Satın Alma ve Tedarik Uzmanı",
      },
      {
        value: "Bilgi Teknolojileri Müdürü",
        label: "Bilgi Teknolojileri Müdürü",
      },
      {
        value: "Bilgi Teknolojileri Uzmanı",
        label: "Bilgi Teknolojileri Uzmanı",
      },
      {
        value: "Bilgi Teknolojileri Personeli",
        label: "Bilgi Teknolojileri Personeli",
      },
      { value: "Yönetim Kurulu Sekreteri", label: "Yönetim Kurulu Sekreteri" },
      { value: "İnsan Kaynakları Müdürü", label: "İnsan Kaynakları Müdürü" },
      { value: "İnsan Kaynakları Uzmanı", label: "İnsan Kaynakları Uzmanı" },
      {
        value: "İnsan Kaynakları Personeli",
        label: "İnsan Kaynakları Personeli",
      },
      { value: "İdari İşler Bölüm Şefi", label: "İdari İşler Bölüm Şefi" },
      {
        value: "Bakım Onarım & Teknik İşler Kısım Şefi",
        label: "Bakım Onarım & Teknik İşler Kısım Şefi",
      },
      { value: "Güvenlik Personeli", label: "Güvenlik Personeli" },
      { value: "İdari Personeli", label: "İdari Personeli" },
      { value: "Servis Görevlisi", label: "Servis Görevlisi" },
      { value: "Resepsiyonist", label: "Resepsiyonist" },
      { value: "Muhasebe Personeli", label: "Muhasebe Personeli" },
      { value: "Gelirler Kontrol Uzmanı", label: "Gelirler Kontrol Uzmanı" },
      { value: "Ön Muhasebe Personeli", label: "Ön Muhasebe Personeli" },
      { value: "Bordro Personeli", label: "Bordro Personeli" },
      {
        value: "Finans ve Bütçe Planlama Müdürü",
        label: "Finans ve Bütçe Planlama Müdürü",
      },
      { value: "Finans Sorumlusu", label: "Finans Sorumlusu" },
      { value: "Finans Personeli", label: "Finans Personeli" },
      {
        value: "Finans Operasyon Personeli",
        label: "Finans Operasyon Personeli",
      },
      { value: "Ön Muhasebe Şefi", label: "Ön Muhasebe Şefi" },
      { value: "Muhasebe Müdürü", label: "Muhasebe Müdürü" },
      { value: "Satın Alma Uzmanı", label: "Satın Alma Uzmanı" },
      {
        value: "Filo & İdari İşler Personeli",
        label: "Filo & İdari İşler Personeli",
      },
      {
        value: "Market Operasyon Yöneticisi",
        label: "Market Operasyon Yöneticisi",
      },
      { value: "Veri & Raporlama Uzmanı", label: "Veri & Raporlama Uzmanı" },
      { value: "Market Operasyon Uzmanı", label: "Market Operasyon Uzmanı" },
      { value: "Gümrük&Lojistik Müdürü", label: "Gümrük&Lojistik Müdürü" },
      { value: "Gümrük&Lojistik Şefi", label: "Gümrük&Lojistik Şefi" },
      { value: "Gümrük Personeli", label: "Gümrük Personeli" },
      { value: "Antrepo Şefi", label: "Antrepo Şefi" },
      { value: "Antrepo Şef Yardımcısı", label: "Antrepo Şef Yardımcısı" },
      {
        value: "İç Pazar Operasyon Müdürü",
        label: "İç Pazar Operasyon Müdürü",
      },
      {
        value: "Stratejik Planlama Yöneticisi",
        label: "Stratejik Planlama Yöneticisi",
      },
      { value: "Satış Temsilcisi", label: "Satış Temsilcisi" },
      { value: "Dijital Pazarlama Uzmanı", label: "Dijital Pazarlama Uzmanı" },
      { value: "Tanzım-Teşhir Personeli", label: "Tanzım-Teşhir Personeli" },
      { value: "Forklift Operatörü", label: "Forklift Operatörü" },
      { value: "Depo Personeli", label: "Depo Personeli" },
      { value: "Gözetim Personeli", label: "Gözetim Personeli" },
      { value: "Barista", label: "Barista" },
      { value: "Sekreter", label: "Sekreter" },
      { value: "İdari İşler Personeli", label: "İdari İşler Personeli" },
      { value: "İdari İşler Bölüm Şefi", label: "İdari İşler Bölüm Şefi" },
      { value: "Muhasebe Sorumlusu", label: "Muhasebe Sorumlusu" },
      {
        value: "Market Operasyon Temsilcisi",
        label: "Market Operasyon Temsilcisi",
      },
      {
        value: "Saha Planlama ve Analiz Şefi",
        label: "Saha Planlama ve Analiz Şefi",
      },
      { value: "Sevk Planlama Şefi", label: "Sevk Planlama Şefi" },
      { value: "Depo Şefi", label: "Depo Şefi" },
      {
        value: "Ticari Pazarlama Sorumlusu",
        label: "Ticari Pazarlama Sorumlusu",
      },
      { value: "Planlama Analisti", label: "Planlama Analisti" },
      { value: "Satış Destek Şefi", label: "Satış Destek Şefi" },
      { value: "Muavin", label: "Muavin" },
      { value: "Dağıtım Personeli", label: "Dağıtım Personeli" },
      { value: "Grafik Tasarımcı", label: "Grafik Tasarımcı" },
      {
        value: "Pazarlama Destek Personeli",
        label: "Pazarlama Destek Personeli",
      },
      { value: "Pazarlama Uzmanı", label: "Pazarlama Uzmanı" },
      {
        value: "Lojistik ve Tedarik Uzmanı",
        label: "Lojistik ve Tedarik Uzmanı",
      },
      {
        value: "Lojistik ve Tedarik Personeli",
        label: "Lojistik ve Tedarik Personeli",
      },
      { value: "Bilgi İşlem Personeli", label: "Bilgi İşlem Personeli" },
      {
        value: "Bilgi İşlem ve Veri Müdürü",
        label: "Bilgi İşlem ve Veri Müdürü",
      },
      { value: "Operasyon Müdürü", label: "Operasyon Müdürü" },
      { value: "Mağaza Müdürü", label: "Mağaza Müdürü" },
      { value: "Temizlik Personeli", label: "Temizlik Personeli" },
      { value: "Atölye Şefi", label: "Atölye Şefi" },
      { value: "Atölye Personeli", label: "Atölye Personeli" },
      { value: "Kalite Kontrol Müdürü", label: "Kalite Kontrol Müdürü" },
      { value: "Pizza Ustası", label: "Pizza Ustası" },
      { value: "Paket Servis Elemanı", label: "Paket Servis Elemanı" },
      {
        value: "Proje ve Teknik Mühendisi",
        label: "Proje ve Teknik Mühendisi",
      },
      { value: "Porter", label: "Porter" },
      { value: "Marka Temsilcisi", label: "Marka Temsilcisi" },
    ],
  },
];
