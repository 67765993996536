import { Button } from "antd";
import styles from "./LanguageInfo.module.scss";
import { LanguageOptions, LevelOptions } from "src/utils/data/language";
import { useState, useEffect } from "react";
import { CiCircleRemove } from "react-icons/ci";
import Input from "../../../components/Input/Input";

interface LanguageInfoProps {
  languageInfo: any;
  handleChange: any;
}

export type LanguageEntry = {
  languageName: string;
  languageLevel: string;
};

const LanguageInfo = ({ languageInfo, handleChange }: LanguageInfoProps) => {
  const [languages, setLanguages] = useState<LanguageEntry[]>([]);

  useEffect(() => {
    if (languageInfo && languageInfo.language) {
      setLanguages(languageInfo.language);
    } else {
      setLanguages([{ languageName: "", languageLevel: "" }]);
    }
  }, [languageInfo]);

  const handleLanguageChange = (
    index: number,
    field: keyof LanguageEntry,
    value: string
  ) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][field] = value;
    setLanguages(updatedLanguages);
    handleChange("language", updatedLanguages);
  };

  const addLanguage = () => {
    setLanguages([...languages, { languageName: "", languageLevel: "" }]);
  };

  const removeLanguage = (index: number) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
    handleChange("language", updatedLanguages);
  };

  return (
    <section className={styles.languageContainer}>
      <p className={styles.label}>Dil Bilgisi</p>

      {languages.map((language, index) => (
        <div className={styles.languageInputBox} key={index}>
          <div className={styles.inputContainer}>
            <Input
              method="select"
              name={`languageName-${index}`}
              placeholder="Dil İsmi"
              value={language.languageName}
              formInput={styles.input}
              onChange={(e) =>
                handleLanguageChange(index, "languageName", e.target.value)
              }
              option={LanguageOptions}
            />

            <Input
              method="select"
              name={`languageLevel-${0}`}
              value={language.languageLevel}
              formInput={styles.input}
              onChange={(e) =>
                handleLanguageChange(index, "languageLevel", e.target.value)
              }
              option={LevelOptions}
            />
          </div>
          <div className={styles.icon}>
            <CiCircleRemove
              color={"#777"}
              size={25}
              onClick={() => removeLanguage(index)}
            />
          </div>
        </div>
      ))}
      <Button className={styles.buttonContainer} onClick={addLanguage}>
        Dil ekle
      </Button>
    </section>
  );
};

export default LanguageInfo;
