/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HumanResource.module.scss";
import moment from "moment";
import "moment/locale/tr";
import { ExportExcel } from "./CurriculumVitae/ExportExcel/ExportExcel";
import {
  GetAllReviews,
  GetApplicantsData,
  GetStatus,
} from "src/Services/API/ApplicantService";
import { AllApplicantsDto } from "src/Services/Dtos/ApplicantDtos";
import { xmlDataExport } from "src/utils/data/xmlDataExport";
import Loading from "src/components/Loading/Loading";
import { ClearCache } from "src/Services/CacheService/CacheService";
import logo2 from "../../../images/logo.png";
import { Modal } from "antd";
import { ApplicantReviewDtos } from "src/Services/Dtos/ApplicantReviewDtos";
import { hrSideBar } from "src/utils/data/hrSideBar";
import classNames from "classnames";
import {
  DekstopHrFilter,
  MobilHrFilter,
} from "src/components/HrSideBarFilter/HrSideBarFilter";
const HumanResource = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [applicantData, setApplicantData] = useState<AllApplicantsDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<ApplicantReviewDtos[]>([]);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState<any>([]);
  const applicationStatus = async function fetchUsers() {
    const response = await GetStatus();

    if (response) {
      setStatus(response.result.data);
    }
  };
  useEffect(() => {
    applicationStatus().catch();
  }, []);
  const applicantStatus = status?.map(
    (status: any) => status.attributes.applicantId
  );

  const [filters, setFilters] = useState<{
    [key: string]: string | any;
  }>(() => {
    const localStorageItems = localStorage.getItem("filters");
    const parsedFilters = JSON.parse(localStorageItems || "null");

    if (parsedFilters === null) {
      return {
        drivingLicence: "",
        location: "",
        educationInfo: "",
        departmentChoices: "",
        status: "",
        position: "",
        date: "",
      };
    }

    return parsedFilters;
  });

  const navigate = useNavigate();

  const applicantReviews = async function fetchUsers() {
    const response = await GetAllReviews();

    if (response) {
      setReviews(response.result.data);
    }
  };

  useEffect(() => {
    applicantReviews().catch();
  }, []);
  useEffect(() => {
    fetchUsers().catch();
  }, []);
  async function fetchUsers() {
    moment.locale("tr");
    setIsLoading(true);
    const response = await GetApplicantsData();
    if (response) {
      setApplicantData(response.result.data);
    }
    setIsLoading(false);
  }
  const applicantD = applicantData?.map((item: any) => {
    return item;
  });
  useEffect(() => {
    if (activeStep === 1) {
      setFilters({
        drivingLicence: "",
        location: "",
        educationInfo: "",
        departmentChoices: "",
        status: "",
        position: "",
        date: "",
      });
      setSearchQuery(""); // Optionally reset the search query as well
    }
  }, [activeStep]);
  const applicantWithStatus = applicantD?.map((data) => {
    const statusInfo = status?.find(
      (statusData: any) => statusData.attributes.applicantId === data.id
    );
    return {
      ...data,
      status: statusInfo ? statusInfo.attributes.status : "Yeni Başvuru",
    };
  });

  const applicant = applicantWithStatus

    ?.filter((data: any) => {
      const properties = Object.values(data.attributes)?.join(" ");
      return properties.toLowerCase()?.includes(searchQuery.toLowerCase());
    })
    ?.filter((data: any) => {
      return (
        (filters.drivingLicence === "" ||
          data?.attributes?.drivingLicence?.includes(filters.drivingLicence)) &&
        (filters.location === "" ||
          data?.attributes?.accomodationPlace?.includes(filters.location)) &&
        (filters.educationInfo === "" ||
          data?.attributes?.educationLevel?.includes(filters.educationInfo) ||
          filters.educationInfo?.includes(data?.attributes?.educationLevel)) &&
        (filters.departmentChoices === "" ||
          data?.attributes?.departmentChoices?.includes(
            filters.departmentChoices
          )) &&
        (filters.status === "" || data?.status?.includes(filters.status)) &&
        (filters.position === "" ||
          data?.attributes.position?.includes(filters.position))
      );
    })
    ?.filter((data: any) => {
      // Filter applicants based on date range
      const applicantDate = moment(data?.attributes?.publishedAt);
      const today = moment().endOf("day");
      if (filters.date.startDate && filters.date.endDate) {
        return (
          applicantDate.isSameOrAfter(filters.date.startDate) &&
          applicantDate.isSameOrBefore(filters.date.endDate)
        );
      } else {
        return applicantDate.isSameOrBefore(today);
      }
    })
    ?.sort((a: any, b: any) =>
      moment(b?.attributes?.publishedAt).diff(
        moment(a?.attributes?.publishedAt)
      )
    )
    ?.map((data: any) => ({
      key: data?.id,
      name: data?.attributes?.name,
      surname: data?.attributes?.surname,
      position: data?.attributes?.position,
      phoneNumber: data?.attributes?.phoneNumber,
      birthDate: data?.attributes?.birthDate,
      birthPlace: data?.attributes?.birthPlace,
      gender: data?.attributes?.gender,
      maritalStatus: data?.attributes?.maritalStatus,
      accomodationPlace: data?.attributes?.accomodationPlace,
      departmentChoices: data?.attributes?.departmentChoices,
      date: moment(data?.attributes?.publishedAt).format("LL"),
      imageUrl: data?.attributes?.imageUrl,
      location: data?.attributes?.accomodationPlace,
      drivingLicence: data?.attributes?.drivingLicence,
      email: data?.attributes?.email,
      educationLevel: data?.attributes?.educationLevel,
      liseName: data?.attributes?.liseName,
      liseGraduationYear: data?.attributes?.liseGraduationYear,
      universityName: data?.attributes?.universityName,
      universityGraduationYear: data?.attributes?.universityGraduationYear,
      universityDepartment: data?.attributes?.universityDepartment,
      masterName: data?.attributes?.masterName,
      masterGraduationYear: data?.attributes?.masterGraduationYear,
      masterDepartment: data?.attributes?.masterDepartment,
      doctorateName: data?.attributes?.doctorateName,
      doctorateGraduationYear: data?.attributes?.doctorateGraduationYear,
      doctorateDepartment: data?.attributes?.doctorateDepartment,
      nationality: data?.attributes?.nationality,
      language: data?.attributes?.language,
      experience: data?.attributes?.experience,
      careerInfo: data?.attributes?.careerInfo,
      publishedAt: data?.attributes?.publishedAt,
      courseName: data?.attributes?.courseName,
      courseYear: data?.attributes?.courseYear,
      referenceName: data?.attributes?.referenceName,
      referenceJob: data?.attributes?.referenceJob,
      referencePhoneNumber: data?.attributes?.referencePhoneNumber,
      referenceEmail: data?.attributes?.referenceEmail,
      status: data?.status,
    }));

  const applicantsWithoutStatus = applicant?.filter(
    (applicant) => !applicantStatus?.includes(applicant?.key)
  );
  const applicationInProgress = applicant?.filter(
    (applicant) => applicant.status === "Değerlendirmede"
  );
  const applicantInInterview = applicant?.filter(
    (applicant) => applicant.status === "Görüşmeye çağrıldı"
  );
  const applicantEvaluatedLater = applicant?.filter(
    (applicant) => applicant.status === "Daha sonra değerlendirilecek"
  );
  const applicantAccepted = applicant?.filter(
    (applicant) => applicant.status === "İşe alındı"
  );
  const applicantDeclined = applicant?.filter(
    (applicant) => applicant.status === "Red"
  );
  const handleApplicantId = (id: any) => {
    navigate(`/başvuran-listesi/${id}`);
  };
  const handleReviewModalId = (id: any) => {
    navigate(`/başvuran-listesi/değerlendirme/${id}`);
  };
  const handleStepChange = (nextStep: number) => {
    setActiveStep(nextStep);
  };
  return (
    <div className={styles.humanResourceRoot}>
      {isLoading ? (
        <div className={styles.loading}>
          <Loading label={"Yükleniyor..."} />
        </div>
      ) : (
        <div className={styles.hr}>
          <div className={styles.filterComponent}>
            <div className={styles.leftBar}>
              <div className={styles.sidebar}>
                <div className={styles.headOfHr}>
                  <div className={styles.sidebarHeaderLogo}>
                    <img
                      src={logo2}
                      alt="kaner-logo"
                      width={"60%"}
                      height={"50%"}
                      className={styles.kanerLogo}
                    />
                  </div>
                </div>
                <div className={styles.hrSideBarRoot}>
                  {hrSideBar.map((title: any, id: number) => (
                    <div
                      key={title.id}
                      onClick={() => handleStepChange(id)}
                      className={classNames(
                        styles.sidebarItemContainer,
                        activeStep === id && styles.clicked
                      )}
                    >
                      <div className={styles.sidebarIcon}>{title.icon}</div>
                      <h4 className={styles.titleText}>{title.name}</h4>
                    </div>
                  ))}
                </div>
              </div>
              <MobilHrFilter
                activeStep={activeStep}
                setLogoutModal={setLogoutModal}
                filters={filters}
                setFilters={setFilters}
                setSearchQuery={setSearchQuery}
                setStatus={setStatus}
                status={status}
                applicant={applicant}
                handleApplicantId={handleApplicantId}
                setReviews={setReviews}
                reviews={reviews}
                handleReviewModalId={handleReviewModalId}
                applicantsWithoutStatus={applicantsWithoutStatus}
                mobileApplicantStyle={styles.mobilApplicantTable}
                applicationInProgress={applicationInProgress}
                applicantInInterview={applicantInInterview}
                applicantEvaluatedLater={applicantEvaluatedLater}
                applicantAccepted={applicantAccepted}
                applicantDeclined={applicantDeclined}
              />
              {activeStep === 0 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicant || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 1 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicantsWithoutStatus || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 2 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicationInProgress || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 3 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicantInInterview || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 4 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicantEvaluatedLater || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 5 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicantAccepted || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
              {activeStep === 6 && (
                <div className={styles.exportExcel}>
                  <ExportExcel
                    excelData={xmlDataExport(applicantDeclined || [])}
                    fileName={"Export"}
                  />
                </div>
              )}
            </div>
          </div>

          <DekstopHrFilter
            activeStep={activeStep}
            setLogoutModal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearchQuery={setSearchQuery}
            setStatus={setStatus}
            status={status}
            applicant={applicant}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalId={handleReviewModalId}
            applicantsWithoutStatus={applicantsWithoutStatus}
            mobileApplicantStyle={styles.mobilApplicantTable}
            applicationInProgress={applicationInProgress}
            applicantInInterview={applicantInInterview}
            applicantEvaluatedLater={applicantEvaluatedLater}
            desktopApplicantStyle={styles.applicantTable}
            applicantAccepted={applicantAccepted}
            applicantDeclined={applicantDeclined}
          />
        </div>
      )}
      {logoutModal && (
        <Modal
          title="Çıkış Yap"
          open={logoutModal}
          onCancel={() => {
            setLogoutModal(false);
          }}
          onOk={() => {
            ClearCache();
            window.location.reload();
          }}
          cancelText="İptal"
        >
          <p>Çıkış yapmak istediğinizden emin misiniz?</p>
        </Modal>
      )}
    </div>
  );
};
export default HumanResource;
