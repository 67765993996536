import { useEffect, useState } from "react";
// import { API } from "src/constant";
import { Sidebar } from "src/utils/data/sidebar";
import {
  renderSection,
  InputInfo,
  initialFormState,
} from "src/utils/data/sidebar";
import logo2 from "../../../images/logo.png";
import { notification } from "antd";
import styles from "./Dashboard.module.scss";
import classNames from "classnames";
import { formValidation } from "src/utils/data/formValidation";
import Loading from "src/components/Loading/Loading";
import { config } from "src/config";
const BASE_URL = config.BASEURL + "api/";
const CLOUDINARY_POST_URL = process.env.REACT_APP_POST_CLOUDINARY_URL;
const SideBar = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const MAX_FILE_SIZE = 1024 * 1024; // 1 MB in bytes
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState<InputInfo>(initialFormState);
  const [formErrors, setFormErrors] = useState<Partial<InputInfo>>({});
  const [loading, setLoading] = useState(false);

  const handleReset = () => {
    setCurrentStep(0);
    setForm(initialFormState);
  };
  const [selectedValues, setSelectedValues] = useState<any>({});
  const handleStepChange = (newStep: number) => {
    const errors = formValidation(currentStep, form);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setActiveStep(newStep);
      setCurrentStep(newStep);
    }
  };
  const handleNext = () => {
    const errors = formValidation(currentStep, form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setCurrentStep(currentStep + 1);
    setActiveStep(currentStep + 1);
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    setActiveStep(currentStep - 1);
  };
  const onSubmit = async (event: any) => {
    event.preventDefault();

    // Validate form fields (excluding image)
    const errors = formValidation(currentStep, form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Create form data
    const formData = new FormData();
    if (form.imageFile) {
      formData.append("file", form.imageFile);
      formData.append("upload_preset", "dqj5xldj");
    }

    try {
      setLoading(true);

      let imageID = null;

      // Upload image if it exists
      if (form.imageFile) {
        const uploadResponse = await fetch(
          `${CLOUDINARY_POST_URL}image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (uploadResponse.ok) {
          const uploadData = await uploadResponse.json();
          imageID = uploadData.public_id;
        }
      }

      // Prepare personal info data
      const personalInfoData = {
        data: {
          ...form,
          imageUrl: imageID, // This will be null if no image was uploaded
        },
      };

      // Send personal information data
      const personalInfoResponse = await fetch(
        `${BASE_URL}personal-informations`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(personalInfoData),
        }
      );

      setLoading(false);

      if (personalInfoResponse.ok) {
        notification["success"]({
          message: "Başarılı",
          description: "Verileriniz başarılı bir şekilde gönderilmiştir.",
          duration: 5,
        });
        handleReset();
      } else {
        notification["error"]({
          message: "Hata",
          description:
            "Verileriniz gönderilirken bir hata oluştu. Lütfen tekrar deneyin.",
          duration: 5,
        });
      }
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Hata",
        description: error.message,
        duration: 5,
      });
    }
  };

  // const onSubmit = async (event: any) => {
  //   const errors = formValidation(currentStep, form);

  //   if (Object.keys(errors).length > 0) {
  //     setFormErrors(errors);
  //     return;
  //   }
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append("file", form.imageFile);
  //   formData.append("upload_preset", "dqj5xldj");

  //   if (form.imageFile.length === 0) {
  //     notification["error"]({
  //       message: "Hata",
  //       description:
  //         "Fotograf verisi yüklenmediği için hata oluştu. Lütfen tekrar deneyin.",
  //       duration: 5,
  //     });
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const uploadResponse = await fetch(`${CLOUDINARY_POST_URL}image/upload`, {
  //       method: "POST",
  //       body: formData,
  //     });
  //     if (uploadResponse.ok) {
  //       const uploadData = await uploadResponse.json();
  //       const imageID = uploadData.public_id;
  //       const personalInfoData = {
  //         data: {
  //           ...form,
  //           imageUrl: imageID,
  //         },
  //       };
  //       const personalInfoResponse = await fetch(
  //         `${BASE_URL}personal-informations`,
  //         {
  //           method: "POST",
  //           mode: "cors",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(personalInfoData),
  //         }
  //       );
  //       setLoading(false);
  //       if (personalInfoResponse.ok) {
  //         notification["success"]({
  //           message: "Başarılı",
  //           description: "Verileriniz başarılı bir şekilde gönderilmiştir.",
  //           duration: 5,
  //         });
  //         handleReset();
  //       }
  //     } else {
  //       notification["error"]({
  //         message: "Hata",
  //         description:
  //           "Fotograf verisi yüklenmediği için hata oluştu. Lütfen tekrar deneyin.",
  //         duration: 5,
  //       });
  //     }
  //   } catch (error: any) {
  //     notification["error"]({
  //       message: "Hata",
  //       description: error.message,
  //       duration: 5,
  //     });
  //   }
  // };
  const handleChange = (name: string, value: any) => {
    if (name === "imageFile") {
      const file = value?.target?.files[0];
      if (file.type === "image/heic" || file.type === "application/pdf") {
        // Notify the user that HEIC and PDF files are not accepted
        notification.error({
          message: "Dosya Kabul Edilmiyor",
          description:
            "Yüklemeye çalıştığınız dosya HEIC veya PDF formatında. Lütfen başka bir dosya türü kullanın.",
        });
      } else if (file && file.size > MAX_FILE_SIZE) {
        notification.error({
          message: "Dosya Boyutu Aşılıyor",
          description:
            "Yüklemeye çalıştığınız dosyanın boyutu 1MB üzerindedir,izin verilen sınırı aşıyor.",
        });
      } else {
        setForm((prevFormState) => ({
          ...prevFormState,
          [name]: file,
        }));
        notification.success({
          message: "Dosya Yüklendi",
          description: "Dosya başarıyla yüklendi.",
        });
      }
    } else {
      let updatedValue = value;
      if (
        typeof value === "string" &&
        name !== "email" &&
        name !== "referenceEmail"
      ) {
        updatedValue = capitalizeFirstLetter(value);
      }

      setForm((prevForm) => ({
        ...prevForm,
        [name]: updatedValue,
      }));
      setSelectedValues((prevSelectedValues: any) => ({
        ...prevSelectedValues,
        [name]: updatedValue,
      }));
    }
  };
  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };
  useEffect(() => {
    setSelectedValues(form);
  }, [form]);
  return (
    <div className={styles.applicationForm}>
      {loading ? (
        <Loading label={"Gönderiliyor..."} />
      ) : (
        <>
          <div className={styles.sidebarRoot}>
            <div className={styles.sidebarHeader}>
              <div className={styles.sidebarHeaderLogo}>
                <img
                  src={logo2}
                  alt="kaner-logo"
                  width={"60%"}
                  height={"50%"}
                  className={styles.kanerLogo}
                />
              </div>
            </div>
            <div className={styles.sidebarContentContainer}>
              <div className={styles.sidebarContent}>
                <div className={styles.sidebarItems}>
                  {Sidebar.map((title: any, id: number) => (
                    <div
                      key={title.id}
                      onClick={() => handleStepChange(id)}
                      className={classNames(
                        styles.sidebarItemContainer,
                        activeStep === id && styles.clicked
                      )}
                    >
                      <div className={styles.sidebarIcon}>{title.icon}</div>
                      <h4 className={styles.titleText}>{title.name}</h4>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.mobilForm}>
                {renderSection(
                  currentStep,
                  handleChange,
                  form,
                  selectedValues,
                  styles,
                  formErrors,
                  setFormErrors
                )}
                <div className={classNames(styles.footerFrame, styles.footer)}>
                  <div className={styles.buttonContainer}>
                    {currentStep !== 0 && (
                      <button className={styles.button} onClick={handlePrev}>
                        Geri
                      </button>
                    )}
                    {currentStep !== 6 && (
                      <button
                        className={classNames(styles.button, styles.nextButton)}
                        onClick={handleNext}
                      >
                        İleri
                      </button>
                    )}
                    {currentStep === 6 && (
                      <button onClick={onSubmit} className={styles.button}>
                        Gönder
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formContent}>
            {renderSection(
              currentStep,
              handleChange,
              form,
              selectedValues,
              styles,
              formErrors,
              setFormErrors
            )}

            <div className={classNames(styles.footerFrame, styles.footer)}>
              <div className={styles.buttonContainer}>
                {currentStep !== 0 && (
                  <button className={styles.button} onClick={handlePrev}>
                    Geri
                  </button>
                )}
                {currentStep !== 6 && (
                  <button className={styles.button} onClick={handleNext}>
                    İleri
                  </button>
                )}
                {currentStep === 6 && (
                  <button onClick={onSubmit} className={styles.button}>
                    Gönder
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default SideBar;
