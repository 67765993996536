import { useEffect } from "react";
import { RoutesProps } from "react-router-dom";
import {
  ClearCache,
  getCachedToken,
  getCachedUser,
} from "../Services/CacheService/CacheService";
import jwtDecode from "jwt-decode";
import { CustomAxiosHelpersGet } from "../Services/API/CustomAxiosHelpers";

export const AuthProvider = ({ children }: RoutesProps): React.ReactElement => {
  useEffect(() => {
    initializeAsync().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeAsync = async () => {
    const getToken = getCachedToken();
    if (getCachedToken()) {
      if (isValidToken(getToken)) {
        const cachedUser = getCachedUser();
        if (cachedUser) {
          const user = await CustomAxiosHelpersGet(`users/${cachedUser.id}`);
          if (user) return;
        }
      }
    }

    ClearCache();
  };

  const isValidToken = (accessToken: string) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  return <>{children}</>;
};
