import React, { useState, useEffect } from "react";
import styles from "./Career.module.scss";
import FormSection from "../../../components/SectionBox/SectionBox";
import { Button, Select } from "antd";
import { CareerInfo } from "src/utils/data/career";

interface CareerProps {
  careerInfo: any;
  formErrors: any;
  setFormErrors: any;
  handleChange: (fieldName: string, value: any) => void;
}
interface ExperienceItem {
  workName: string;
  jobTitle: string;
  startDate: string;
  isCurrentlyWorking: string;
}

const Career = ({
  careerInfo,
  handleChange,
  formErrors,
  setFormErrors,
}: CareerProps) => {
  const [experienceList, setExperienceList] = useState<any[]>([
    { workName: "", jobTitle: "", startDate: "", isCurrentlyWorking: "" },
  ]);
  useEffect(() => {
    if (careerInfo && careerInfo.experience) {
      setExperienceList(careerInfo.experience);
    } else {
      setExperienceList([
        { workName: "", jobTitle: "", startDate: "", isCurrentlyWorking: "" },
      ]);
    }
  }, [careerInfo]);
  const handleExperienceChange = (
    index: number,
    field: keyof ExperienceItem,
    value: string
  ) => {
    const updatedExperienceList = experienceList.slice();

    if (index === -1) {
      const initialEmptyExperience: ExperienceItem = {
        workName: "",
        jobTitle: "",
        startDate: "",
        isCurrentlyWorking: "",
      };

      if (field === "jobTitle" || field === "workName") {
        value = value.charAt(0).toUpperCase() + value.slice(1);
      }

      initialEmptyExperience[field] = value;
      updatedExperienceList.push(initialEmptyExperience);
    } else if (index >= 0 && index < updatedExperienceList.length) {
      if (field === "jobTitle" || field === "workName") {
        value = value.charAt(0).toUpperCase() + value.slice(1);
      }

      updatedExperienceList[index] = {
        ...updatedExperienceList[index],
        [field]: value,
      };
    }

    handleChange("experience", updatedExperienceList);
  };

  const addExperience = () => {
    setExperienceList([
      ...experienceList,
      { workName: "", jobTitle: "", startDate: "", isCurrentlyWorking: "" },
    ]);
  };

  const removeExperience = (index: number) => {
    const updatedExperienceList = [...experienceList];
    updatedExperienceList.splice(index, 1);
    handleChange("experience", updatedExperienceList);
  };
  const getErrorMessage = (fieldName: string) => {
    if (formErrors && formErrors[fieldName]) {
      return formErrors[fieldName];
    }
    return "";
  };
  return (
    <section className={styles.careerSection}>
      <div className={styles.selection}>
        <label className={styles.label}>İş deneyiminiz var mı?</label>
        <div className={styles.careerSelection}>
          <Select
            style={{ width: "100%", margin: "auto" }}
            value={careerInfo.careerInfo || "Seçiniz"}
            onChange={(value: string) => handleChange("careerInfo", value)}
            options={[
              { value: "Deneyimsiz", label: "Deneyimsiz" },
              { value: "Deneyimli", label: "Deneyimli" },
            ]}
          />
          {formErrors && (
            <p className={styles.errorText}>{getErrorMessage("careerInfo")}</p>
          )}
        </div>
      </div>
      {careerInfo.careerInfo === "Deneyimli" && (
        <>
          <Button
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={addExperience}
          >
            Deneyim Ekle
          </Button>
          {experienceList.map((experience, index) => (
            <div key={index} className={styles.additionalField}>
              <FormSection
                fields={CareerInfo}
                formState={experience}
                handleChange={(field: keyof ExperienceItem, value: string) =>
                  handleExperienceChange(index, field, value)
                }
                setFormErrors={setFormErrors}
                formErrors={formErrors}
              />

              {experienceList.length > 1 && (
                <Button
                  onClick={() => removeExperience(index)}
                  style={{ marginTop: 10 }}
                >
                  Sil
                </Button>
              )}
            </div>
          ))}

          {experienceList.length === 0 && (
            <div className={styles.additionalField}>
              <FormSection
                fields={CareerInfo}
                formState={{ workName: "", jobTitle: "", startDate: "" }}
                handleChange={(field: keyof ExperienceItem, value: string) =>
                  handleExperienceChange(-1, field, value)
                }
                setFormErrors={setFormErrors}
                formErrors={formErrors}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Career;
