import ApplicantTable from "src/Pages/AdminSide/HumanResource/ApplicantTable/ApplicantTable";
import { SearchBarContainer } from "../SearchBarContainer/SearchBarContainer";

interface Props {
  activeStep: any;
  setLogoutModal: any;
  filters: any;
  setFilters: any;
  setSearchQuery: any;
  setStatus: any;
  status: any;
  applicant: any;
  handleApplicantId: any;
  setReviews: any;
  reviews: any;
  handleReviewModalId: any;
  applicantsWithoutStatus: any;
  mobileApplicantStyle: any;
  applicationInProgress: any;
  applicantInInterview: any;
  applicantEvaluatedLater: any;
  desktopApplicantStyle?: any;
  applicantAccepted: any;
  applicantDeclined: any;
}
export const MobilHrFilter = ({
  activeStep,
  setLogoutModal,
  filters,
  setFilters,
  setSearchQuery,
  setStatus,
  status,
  applicant,
  handleApplicantId,
  setReviews,
  reviews,
  handleReviewModalId,
  mobileApplicantStyle,
  applicantsWithoutStatus,
  applicationInProgress,
  applicantInInterview,
  applicantEvaluatedLater,
  applicantAccepted,
  applicantDeclined,
}: Props) => {
  return (
    <>
      {activeStep === 0 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
            numberOfApplicant={applicant?.length}
          />

          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicant}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 1 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantsWithoutStatus?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantsWithoutStatus}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 2 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicationInProgress?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicationInProgress}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 3 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantInInterview?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantInInterview}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 4 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantEvaluatedLater?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantEvaluatedLater}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 5 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantAccepted?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantAccepted}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 6 && (
        <div className={mobileApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantDeclined?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantDeclined}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
    </>
  );
};

export const DekstopHrFilter = ({
  activeStep,
  setLogoutModal,
  filters,
  setFilters,
  setSearchQuery,
  setStatus,
  status,
  applicant,
  handleApplicantId,
  setReviews,
  reviews,
  handleReviewModalId,
  mobileApplicantStyle,
  applicantsWithoutStatus,
  applicationInProgress,
  applicantInInterview,
  applicantEvaluatedLater,
  desktopApplicantStyle,
  applicantAccepted,
  applicantDeclined,
}: Props) => {
  return (
    <>
      {activeStep === 0 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            numberOfApplicant={applicant?.length}
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicant}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 1 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            numberOfApplicant={applicantsWithoutStatus?.length}
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantsWithoutStatus}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 2 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            numberOfApplicant={applicationInProgress?.length}
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicationInProgress}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 3 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            filters={filters}
            numberOfApplicant={applicantInInterview?.length}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantInInterview}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 4 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            numberOfApplicant={applicantEvaluatedLater?.length}
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantEvaluatedLater}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 5 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            numberOfApplicant={applicantAccepted?.length}
            setLogoutmodal={setLogoutModal}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantAccepted}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
      {activeStep === 6 && (
        <div className={desktopApplicantStyle}>
          <SearchBarContainer
            setLogoutmodal={setLogoutModal}
            numberOfApplicant={applicantDeclined?.length}
            filters={filters}
            setFilters={setFilters}
            setSearhQuery={setSearchQuery}
          />
          <ApplicantTable
            setStatus={setStatus}
            status={status}
            applicant={applicantDeclined}
            handleApplicantId={handleApplicantId}
            setReviews={setReviews}
            reviews={reviews}
            handleReviewModalPage={handleReviewModalId}
          />
        </div>
      )}
    </>
  );
};
