import { columnOrder } from "./xmlConstants";

interface LanguageDetails {
  languageName: string;
  languageLevel: string;
}
interface ExperienceDetails {
  workName: string;
  jobTitle: string;
}

interface ApplicantData {
  name: string;
  surname: string;
  gender: string;
  birthDate: string;
  nationality: string[] | null;
  phoneNumber: string;
  drivingLicence: string[] | null;
  email: string;
  accomodationPlace: string;
  language: LanguageDetails[] | null;
  experience: ExperienceDetails[] | null;
  position: any;
  referenceName: string;
  educationLevel: string;
  universityName: string;
  liseName: string;
  doctorateName: string;
  masterName: string;
  universityDepartment: string;
  masterDepartment: string;
  doctorateDepartment: string;
  date: any;
}

export const xmlDataExport = (applicantData: ApplicantData[]) => {
  const dataExports: any[] = [];

  applicantData?.forEach((item) => {
    const listItem: Record<string, any> = {};

    columnOrder.forEach((column) => {
      if (column === "Başvuru Tarihi") {
        listItem[column] = item.date;
      } else if (column === "ADI-SOYADI") {
        listItem[column] = item.name + " " + item.surname;
      } else if (column === "Cinsiyet") {
        listItem[column] = item.gender;
      } else if (column === "Doğum Tarihi") {
        listItem[column] = item.birthDate;
      } else if (column === "Uyruk") {
        listItem[column] = Array.isArray(item.nationality)
          ? item.nationality.join(", ")
          : "";
      } else if (column === "Tel.") {
        listItem[column] = item.phoneNumber;
      } else if (column === "Ehliyet") {
        listItem[column] = Array.isArray(item.drivingLicence)
          ? item.drivingLicence.join(", ")
          : "";
      } else if (column === "E-mail") {
        listItem[column] = item.email;
      } else if (column === "Adres Bölge") {
        listItem[column] = item.accomodationPlace;
      } else if (column === "Adres Detay") {
        listItem[column] = "-";
      } else if (column === "En Son Bitirdiği Okul") {
        listItem[column] = item.educationLevel || "";
      } else if (column === "Okul Adı") {
        if (item.educationLevel === "Üniversite") {
          listItem[column] = item?.universityName || "";
        } else if (item.educationLevel === "Lise") {
          listItem[column] = item?.liseName || "";
        } else if (item.educationLevel === "Yüksek Lisans") {
          listItem[column] = item?.masterName || "";
        } else if (item.educationLevel === "Doktora") {
          listItem[column] = item?.doctorateName || "";
        } else {
          listItem[column] = "-";
        }
      } else if (column === "Bölüm") {
        if (item.educationLevel === "Üniversite") {
          listItem[column] = item?.universityDepartment || "";
        } else if (item.educationLevel === "Lise") {
          listItem[column] = "-";
        } else if (item.educationLevel === "Yüksek Lisans") {
          listItem[column] = item?.masterDepartment || "";
        } else if (item.educationLevel === "Doktora") {
          listItem[column] = item?.doctorateDepartment || "";
        } else {
          listItem[column] = "-";
        }
      } else if (column === "Lisan") {
        listItem[column] =
          item.language && item.language.length > 0
            ? item.language[0].languageName || "-"
            : "-";
      } else if (column === "Konuşma") {
        listItem[column] =
          item.language && item.language.length > 0
            ? item.language[0].languageLevel || "-"
            : "-";
      } else if (column === "Yazma") {
        listItem[column] = item.language && item.language.length > 0 ? "" : "-";
      } else if (column === "Anlama") {
        listItem[column] = item.language && item.language.length > 0 ? "" : "-";
      } else if (column === "2. Lisan") {
        listItem[column] =
          item.language && item.language.length > 1
            ? item.language[1].languageName || "-"
            : "-";
      } else if (column === "2. Lisan Konuşma") {
        listItem[column] =
          item.language && item.language.length > 1
            ? item.language[1].languageLevel || "-"
            : "-";
      } else if (column === "2. Lisan Yazma") {
        listItem[column] = item.language && item.language.length > 1 ? "" : "-";
      } else if (column === "2. Lisan Anlama") {
        listItem[column] = item.language && item.language.length > 1 ? "" : "-";
      } else if (column === "3. Lisan") {
        listItem[column] =
          item.language && item.language.length > 2
            ? item.language[2].languageName || "-"
            : "-";
      } else if (column === "3. Lisan Konuşma") {
        listItem[column] =
          item.language && item.language.length > 2
            ? item.language[2].languageLevel || "-"
            : "-";
      } else if (column === "3. Lisan Yazma") {
        listItem[column] = item.language && item.language.length > 2 ? "" : "-";
      } else if (column === "3. Lisan Anlama") {
        listItem[column] = item.language && item.language.length > 2 ? "" : "-";
      } else if (column === "Firma") {
        listItem[column] =
          item.experience && item.experience.length > 0
            ? item.experience[0].workName || "-"
            : "-";
      } else if (column === "Görev") {
        listItem[column] =
          item.experience && item.experience.length > 0
            ? item.experience[0].jobTitle || "-"
            : "-";
      } else if (column === "Firma2") {
        listItem[column] =
          item.experience && item.experience.length > 1
            ? item.experience[1].workName || "-"
            : "-";
      } else if (column === "Görev2") {
        listItem[column] =
          item.experience && item.experience.length > 1
            ? item.experience[1].jobTitle || "-"
            : "-";
      } else if (column === "Firma3") {
        listItem[column] =
          item.experience && item.experience.length > 2
            ? item.experience[2].workName || "-"
            : "-";
      } else if (column === "Görev3") {
        listItem[column] =
          item.experience && item.experience.length > 2
            ? item.experience[2].jobTitle || "-"
            : "-";
      } else if (column === "Pozisyon1") {
        listItem[column] = item.position[0] || "-";
      } else if (column === "Pozisyon2") {
        listItem[column] = item.position[1] || "-";
      } else if (column === "Pozisyon3") {
        listItem[column] = item.position[2] || "-";
      } else if (column === "Pozisyon4") {
        listItem[column] = item.position[3] || "-";
      } else if (column === "Referans1") {
        listItem[column] = item.referenceName || "-";
      } else if (column === "Referans2") {
        listItem[column] = "-";
      } else if (column === "Referans3") {
        listItem[column] = "-";
      }
    });

    dataExports.push(listItem);
  });

  return dataExports;
};
