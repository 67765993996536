import { deCrypt, enCrypt } from "../Cryptography";
import { LocalConstants } from "src/constants";
import { UsersDto } from "../Dtos/Users";

export function setCachedToken(data: string) {
  setCache(data, LocalConstants.TOKEN);
}
export function setCachedUser(data: UsersDto) {
  setCache(enCrypt(JSON.stringify(data)), LocalConstants.USER);
}

export function setCachedIsRememberMe(isRememberMe: string) {
  localStorage.setItem(LocalConstants.ISREMEMBER, isRememberMe);
}

export function getCachedToken() {
  return getCache(LocalConstants.TOKEN) as string;
}
export function getCachedUser() {
  try {
    let data = getCache(LocalConstants.USER);
    return data ? (JSON.parse(deCrypt(data)) as UsersDto) : null;
  } catch (ex) {
    return null;
  }
}

function setCache(data: string, key: string) {
  return getCachedIsRemember() === "true"
    ? localStorage.setItem(key, data)
    : sessionStorage.setItem(key, data);
}

function getCache(key: string) {
  return getCachedIsRemember() === "true"
    ? localStorage.getItem(key)
    : sessionStorage.getItem(key);
}

function getCachedIsRemember() {
  if (localStorage.getItem(LocalConstants.ISREMEMBER))
    return localStorage.getItem(LocalConstants.ISREMEMBER);

  localStorage.setItem(LocalConstants.ISREMEMBER, "false");
  return "false";
}

export function ClearCache() {
  localStorage.clear();
  sessionStorage.clear();
}
