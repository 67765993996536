import { Select } from "antd";
import styles from "./Input.module.scss";
import classNames from "classnames";

import { useState } from "react";
const { Option } = Select;
interface InputProps {
  name: string;
  method: "input" | "select" | "checkbox" | string | "richText" | "radio";
  option?: { value: string; label: string; id: string }[];
  label?: string;
  value?: string | string[];
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  labelText?: string;
  inputBox?: string;
  type?: string;
  placeholder?: string;
  formInput?: string;
  textarea?: string;
  disabled?: boolean;
  error?: string;
  checked?: boolean;
  handleChange?: (fieldName: string, values: string[]) => void;
  selectedValues?: { [key: string]: string[] };
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  value,
  onChange,
  checked,
  option,
  method,
  type,
  placeholder,
  error,
  labelText,
  formInput,
  textarea,
  disabled,
  inputBox,
  handleChange,
  selectedValues,
}) => {
  const [internalSelectedValues, setInternalSelectedValues] = useState<
    { [key: string]: string[] } | (() => { [key: string]: string[] }) | any
  >(() => {
    if (selectedValues) {
      return selectedValues;
    }
    return {};
  });

  const handleSelectionChange = (fieldName: string, values: string[]) => {
    setInternalSelectedValues(
      (
        prevSelectedValues:
          | { [key: string]: string[] }
          | (() => { [key: string]: string[] })
      ) => {
        if (typeof prevSelectedValues === "function") {
          return prevSelectedValues();
        }
        return {
          ...prevSelectedValues,
          [fieldName]: values,
        };
      }
    );

    if (handleChange) {
      handleChange(fieldName, values);
    }
  };

  return (
    <div className={classNames(formInput, styles.formInput)}>
      <label
        className={classNames(labelText, styles.inputLabel)}
        htmlFor={name}
      >
        {label}
      </label>
      {method === "input" && (
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          className={classNames(inputBox, styles.inputBox)}
          disabled={disabled}
          autoComplete="given-input"
        />
      )}
      {method === "richText" && (
        <textarea
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          id={name}
          autoComplete="given-input"
          className={classNames(textarea, styles.textArea)}
        />
      )}
      {method === "select" && (
        <select
          style={{ height: "30px", display: "flex", fontSize: 12, padding: 0 }}
          name={name}
          value={value}
          id={name}
          className={styles.inputBox}
          onChange={onChange}
          autoComplete="given-input"
        >
          <option value="">Seçiniz</option>
          {option?.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {method === "checkbox" && (
        <Select
          style={{ width: "80%" }}
          mode="multiple"
          placeholder={placeholder}
          value={internalSelectedValues[name] || []}
          onChange={(values) => handleSelectionChange(name, values)}
        >
          {option?.map((option) => (
            <Option key={option.id} value={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}

      <div className={styles.radioInputContainer}>
        {method === "radio" &&
          option?.map((option) => (
            <div key={option.id}>
              <label className={styles.radioLabel} htmlFor={option.id}>
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  id={option.id}
                  onChange={onChange}
                />
                {option.value}
              </label>
            </div>
          ))}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Input;
