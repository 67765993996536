import { AuthProvider } from "./AuthProvider/AuthProvider";
import { Navigation } from "./Navigation";
import "./index.scss";
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    </div>
  );
}

export default App;
