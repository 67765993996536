export const applicationInfo = [
  {
    id: 1,
    method: "select",
    name: "departmentChoices",
    placeholder: "Bölüm Tercihi",
    label: "Bölüm Tercihi",
    options: [
      { id: 1, value: "bilgiTeknolojileri", label: "IT-Bilgi Teknolojileri" },
      { id: 2, value: "insanKaynaklari", label: "İK-İnsan Kaynakları" },
      { id: 3, value: "idariIsler", label: "İdari İşler" },
      { id: 4, value: "muhasebe", label: "Muhasebe" },
      { id: 5, value: "satinAlma", label: "Satın Alma" },
      { id: 6, value: "filo", label: "Filo" },
      { id: 7, value: "gumruk", label: "Gümrükleme & Lojistik" },
      { id: 8, value: "diaego", label: "Diageo" },
      { id: 9, value: "merkezDepo", label: "Merkez Depo" },
      { id: 10, value: "imperial", label: "IMP - Imperial" },
      { id: 11, value: "philipMorris", label: "PMI - Philip Morris" },
      { id: 12, value: "cocaCola", label: "Coca-Cola" },
      { id: 13, value: "Gloria Jeans Coffees", label: "Gloria Jeans Coffees" },
      { id: 14, value: "dominos", label: "Dominos" },
      { id: 15, value: "gidaSatis", label: "Gıda Satış" },
      { id: 16, value: "kanerBrandHouse", label: "Kaner Brand House" },
      { id: 17, value: "ercanDutyFree", label: "Ercan Duty Free" },
      { id: 18, value: "icPazar", label: "İç Pazar" },
    ],
  },
  {
    id: 2,
    method: "select",
    name: "position",
    placeholder: "Pozisyon",
    label: "Pozisyon",
    options: [
      { id: 1, label: "Operasyon Destek Personeli" },
      { id: 2, label: "Şoför" },
      { id: 3, label: "Stratejik Planlama ve İş Geliştirme Müdürü" },
      { id: 4, label: "Raporlama Uzmanı" },
      { id: 5, label: "Yönetici Asistan" },
      { id: 6, label: "Tedarik Zinciri Müdürü" },
      { id: 7, label: "Stok Sayım Sorumlusu" },
      { id: 8, label: "Stok Sayım Personeli" },
      { id: 9, label: "Ticari Satın Alma ve Tedarik Uzmanı" },
      { id: 10, label: "Bilgi Teknolojileri Müdürü" },
      { id: 11, label: "Bilgi Teknolojileri Uzmanı" },
      { id: 12, label: "Bilgi Teknolojileri Personeli" },
      { id: 13, label: "Yönetim Kurulu Sekreteri" },
      { id: 14, label: "İnsan Kaynakları Müdürü" },
      { id: 15, label: "İnsan Kaynakları Uzmanı" },
      { id: 16, label: "İnsan Kaynakları Personeli" },
      { id: 17, label: "İdari İşler Bölüm Şefi" },
      { id: 18, label: "Bakım Onarım & Teknik İşler Kısım Şefi" },
      { id: 19, label: "Güvenlik Personeli" },
      { id: 20, label: "İdari Personeli" },
      { id: 21, label: "Servis Görevlisi" },
      { id: 22, label: "Resepsiyonist" },
      { id: 23, label: "Muhasebe Personeli" },
      { id: 24, label: "Gelirler Kontrol Uzmanı" },
      { id: 25, label: "Ön Muhasebe Personeli" },
      { id: 26, label: "Bordro Personeli" },
      { id: 27, label: "Finans ve Bütçe Planlama Müdürü" },
      { id: 28, label: "Finans Sorumlusu" },
      { id: 29, label: "Finans Personeli" },
      { id: 30, label: "Finans Operasyon Personeli" },
      { id: 31, label: "Ön Muhasebe Şefi" },
      { id: 32, label: "Muhasebe Müdürü" },
      { id: 33, label: "Satın Alma Uzmanı" },
      { id: 34, label: "Filo & İdari İşler Personeli" },
      { id: 35, label: "Market Operasyon Yöneticisi" },
      { id: 36, label: "Veri & Raporlama Uzmanı" },
      { id: 37, label: "Market Operasyon Uzmanı" },
      { id: 38, label: "Gümrük&Lojistik Müdürü" },
      { id: 39, label: "Gümrük&Lojistik Şefi" },
      { id: 40, label: "Gümrük Personeli" },
      { id: 41, label: "Antrepo Şefi" },
      { id: 42, label: "Antrepo Şef Yardımcısı" },
      { id: 43, label: "İç Pazar Operasyon Müdürü" },
      { id: 44, label: "Stratejik Planlama Yöneticisi" },
      { id: 45, label: "Satış Temsilcisi" },
      { id: 46, label: "Dijital Pazarlama Uzmanı" },
      { id: 47, label: "Tanzım-Teşhir Personeli" },
      { id: 48, label: "Forklift Operatörü" },
      { id: 49, label: "Depo Personeli" },
      { id: 50, label: "Gözetim Personeli" },
      { id: 51, label: "Barista" },
      { id: 52, label: "Sekreter" },
      { id: 53, label: "İdari İşler Personeli" },
      { id: 54, label: "İdari İşler Bölüm Şefi" },
      { id: 55, label: "Muhasebe Sorumlusu" },
      { id: 56, label: "Market Operasyon Temsilcisi" },
      { id: 57, label: "Saha Planlama ve Analiz Şefi" },
      { id: 58, label: "Sevk Planlama Şefi" },
      { id: 59, label: "Depo Şefi" },
      { id: 60, label: "Ticari Pazarlama Sorumlusu" },
      { id: 61, label: "Planlama Analisti" },
      { id: 62, label: "Satış Destek Şefi" },
      { id: 63, label: "Muavin" },
      { id: 64, label: "Dağıtım Personeli" },
      { id: 65, label: "Grafik Tasarımcı" },
      { id: 66, label: "Pazarlama Destek Personeli" },
      { id: 67, label: "Pazarlama Uzmanı" },
      { id: 68, label: "Lojistik ve Tedarik Uzmanı" },
      { id: 69, label: "Lojistik ve Tedarik Personeli" },
      { id: 70, label: "Bilgi İşlem Personeli" },
      { id: 71, label: "Bilgi İşlem ve Veri Müdürü" },
      { id: 72, label: "Operasyon Müdürü" },
      { id: 73, label: "Mağaza Müdürü" },
      { id: 74, label: "Temizlik Personeli" },
      { id: 75, label: "Atölye Şefi" },
      { id: 76, label: "Atölye Personeli" },
      { id: 77, label: "Kalite Kontrol Müdürü" },
      { id: 78, label: "Pizza Ustası" },
      { id: 79, label: "Paket Servis Elemanı" },
      { id: 80, label: "Proje ve Teknik Mühendisi" },
      { id: 81, label: "Porter" },
      { id: 82, label: "Marka Temsilcisi" },
    ],
  },
  {
    id: 3,
    label: "Ehliyet Durumu",
    type: "select",
    name: "drivingLicence",
    placeholder: "Ehliyet Durumu",
    options: [
      {
        id: 1,
        label: "Ehliyetim Yok",
      },
      {
        id: 2,
        label: "A Sınıfı - Otobüs",
      },
      {
        id: 3,
        label: "B Sınıfı - Kamyon, Tır",
      },
      {
        id: 4,
        label: "C Sınıfı - Minibüs",
      },
      {
        id: 5,
        label: "D Sınıfı - Manuel",
      },
      {
        id: 6,
        label: "D Sınıfı - Otomatik vites",
      },
      {
        id: 7,
        label: "E Sınıfı - Traktör, ATV",
      },
      {
        id: 8,
        label: "F Sınıfı - Palet üzeri araçlar",
      },
      {
        id: 9,
        label: "G Sınıfı - Yol Silindiri",
      },
      {
        id: 10,
        label: "H Sınıfı - Motorsiklet",
      },
      {
        id: 11,
        label: "K Sınıfı - Greyder ve Jiletli araçlar",
      },
    ],
  },
];
