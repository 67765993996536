/* eslint-disable jsx-a11y/img-redundant-alt */

import { DatePicker, Input, InputNumber, Select } from "antd";
import styles from "./SectionBox.module.scss";
import classNames from "classnames";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
interface FormSectionProps {
  id?: any;
  fields: any;
  formState: any;
  formContainer?: any;
  onChange?: (event: any) => void;
  selectedValues?: any;
  handleChange?: any;
  formErrors?: any;
  setFormErrors?: any;
}
const FormSection = ({
  fields,
  formState,
  selectedValues,
  formContainer,
  handleChange,
  formErrors,
  setFormErrors,
}: FormSectionProps) => {
  const { Option } = Select;

  const [internalSelectedValues, setInternalSelectedValues] = useState<
    { [key: string]: string[] } | (() => { [key: string]: string[] }) | any
  >(() => {
    if (selectedValues) {
      return selectedValues;
    }
    return {};
  });
  const handleSelectionChange = (fieldName: string, values: string[]) => {
    setInternalSelectedValues(
      (
        prevSelectedValues:
          | { [key: string]: string[] }
          | (() => { [key: string]: string[] })
      ) => {
        if (typeof prevSelectedValues === "function") {
          return prevSelectedValues();
        }
        return {
          ...prevSelectedValues,
          [fieldName]: values,
        };
      }
    );

    if (handleChange) {
      handleChange(fieldName, values);
    }
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null | string[]
  ) => {
    if (formErrors && formErrors[fieldName]) {
      setFormErrors((prevFormErrors: any) => ({
        ...prevFormErrors,
        [fieldName]: "",
      }));
    }
    handleChange(fieldName, value);
  };

  const getErrorMessage = (fieldName: string) => {
    if (formErrors && formErrors[fieldName]) {
      return formErrors[fieldName];
    }
    return "";
  };
  const isInputFieldInError = (fieldName: string) => {
    return formErrors && formErrors[fieldName];
  };
  return (
    <section className={classNames(styles.formContainer, formContainer)}>
      {fields?.map((input: any) => (
        <div className={styles.input} key={input.name}>
          <p className={styles.label}>{input.label}</p>
          {input.method === "input" ? (
            <Input
              style={{
                borderColor: isInputFieldInError(input?.name) && "red",
              }}
              className={styles.inputField}
              name={input?.name}
              placeholder={input.placeholder}
              onChange={(e) => handleInputChange(input.name, e.target.value)} // Use the handleInputChange function here
              value={formState[input?.name] || ""}
            />
          ) : input.method === "number" ? (
            <InputNumber
              style={{
                borderColor: isInputFieldInError(input.name) && "red",
              }}
              className={styles.inputField}
              addonBefore="+90"
              placeholder={input.placeholder}
              value={formState[input?.name || input.placeholder]}
              name={input.name}
              controls={false}
              onChange={(value) => handleInputChange(input.name, value)}
            />
          ) : input.method === "select" ? (
            <Select
              style={{
                borderColor: isInputFieldInError(input.name) && "red",
              }}
              className={styles.inputField}
              value={formState[input.name] || input.placeholder}
              onChange={(value) => handleInputChange(input.name, value)}
            >
              {input?.options?.map((option: any) => (
                <Option key={option.id} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          ) : input.method === "checkbox" ? (
            <Select
              style={{
                borderColor: isInputFieldInError(input.name) && "red",
              }}
              className={styles.inputField}
              mode="multiple"
              placeholder={input.placeholder}
              value={internalSelectedValues[input.name] || []}
              onChange={(values) => handleSelectionChange(input.name, values)}
            >
              {input?.options?.map((option: any) => (
                <Option key={option.id} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          ) : input.method === "yearPicker" ? (
            <DatePicker
              style={{
                borderColor: isInputFieldInError(input.name) && "red",
              }}
              className={styles.inputField}
              onChange={(date, dateString) => {
                handleChange(input.name, date);
              }}
              value={formState[input.name] || ""}
              picker="year"
              placeholder="Seçiniz"
            />
          ) : input.method === "date" ? (
            <DatePicker
              style={{
                borderColor: isInputFieldInError(input.name) && "red",
              }}
              className={styles.inputField}
              onChange={(date, dateString) => {
                handleChange(input.name, date);
              }}
              disabledDate={(current) => {
                if (input.previousDateDisabled) {
                  return moment().add(-15, "year") < current;
                } else {
                  return false;
                }
              }}
              value={formState[input.name] || ""}
              format="DD/MM/YYYY"
              placeholder="GG/AA/YYYY"
            />
          ) : input.method === "file" ? (
            <label htmlFor={input.name} className={styles.fileUpload}>
              {formState?.imageFile?.name ? formState.imageFile.name : "Yükle"}
              <input
                type="file"
                id={input.name}
                name={input.name}
                style={{
                  display: "none",
                }}
                onChange={(value) => handleChange("imageFile", value)}
              />
            </label>
          ) : input.method === "textarea" ? (
            <TextArea
              name={input.name}
              className={styles.textArea}
              maxLength={200}
              value={formState[input.name] || ""}
              placeholder={input.placeholder}
              onChange={(e) => handleChange(input.name, e.target.value)}
            />
          ) : null}
          {getErrorMessage(input.name) && (
            <p className={styles.errorText}>{getErrorMessage(input.name)}</p>
          )}
        </div>
      ))}
    </section>
  );
};

export default FormSection;
