import { LoadingOutlined } from "@ant-design/icons";
interface Props {
  label: any;
}
const Loading = ({ label }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
        justifyContent: "center",
        marginTop: "100px",
      }}
    >
      <LoadingOutlined style={{ color: "#F5F5DA", fontSize: "50px" }} spin />
      <h1>{label}</h1>
    </div>
  );
};
export default Loading;
