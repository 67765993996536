import { ConfigProvider, Button } from "antd";
interface Props {
  label: string;
  borderColor: string;
  labelColor: string;
  onClick?: any;
  style?: any;
}
const CustomisedButton = ({
  label,
  borderColor,
  labelColor,
  onClick,
  style,
}: Props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F47C01",
        },
      }}
    >
      <Button
        onClick={onClick}
        className={style}
        style={{
          borderColor: borderColor,
          color: labelColor,
          width: "fit-content",
        }}
      >
        {label}
      </Button>
    </ConfigProvider>
  );
};
export default CustomisedButton;
